/* eslint-disable import/named */
import { useCallback, useContext, useEffect, useState } from 'react';

import { animated, useTransition } from '@react-spring/web';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { cloneDeep, first } from 'lodash';

import { UserContext } from '@/contexts/UserContext';
import useOutsideClick from '@/hooks/utils/useClickOutsideComponent';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@/hooks/workspace/resources/useTimelineResourceQuery';
import { PROJECT_TYPE } from '@/types/enums';
import { TTimeBlockRange, TTimelineResource } from '@/types/timeline';

import BlockActions from '@/components/Blocks/BlockActions';
import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import styles from './styles.module.css';

type Props = {
  activeBlocks: TTimeBlockRange[];
  left: number;
  width: number;
  capacity: number;
  type: PROJECT_TYPE;
};
export default function ActiveBlockWrapper({
  left,
  width,
  type,
  capacity,
  activeBlocks,
}: Props) {
  const {
    setActiveBlockIds,
    setActiveResourceId,
    setActiveProjectId,
    activeProjectId,
    activeResourceId,
    activeBlockIds,
    onDeleteBlockFn,
    leftPadding,
    updateBlocksAllocation,
  } = useContext(TimelineResourcesContext);

  const { workspaceId } = useContext(UserContext);

  const [shouldShowActions, setShouldShowActions] = useState(false);

  useEffect(() => {
    setShouldShowActions(Boolean(activeBlocks.length > 0));
  }, [activeBlocks]);

  const transition = useTransition(shouldShowActions, {
    config: { duration: 50 },
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  const onCloseActions = useCallback(() => {
    setActiveBlockIds([]);
    setActiveProjectId(null);
    setActiveResourceId(null);
  }, [setActiveBlockIds, setActiveResourceId, setActiveProjectId]);

  const ref = useOutsideClick<HTMLDivElement>(() => {
    onCloseActions();
  });

  const handleMouseDown = (e: React.MouseEvent) => e.stopPropagation();

  const onChangeBlocksAllocationFn = useCallback(
    (allocation: string) => {
      updateBlocksAllocation(allocation);
    },
    [updateBlocksAllocation],
  );

  const block = first(activeBlocks);
  const queryClient = useQueryClient();

  const onChangeBlockDetailFn = useCallback(
    (block: TTimeBlockRange | undefined) => {
      queryClient.setQueriesData<InfiniteData<TTimelineResource[]>>(
        {
          queryKey: [TIMELINE_RESOURCE_QUERY_KEY, workspaceId],
          exact: false,
        },
        (oldData) => {
          if (!oldData) return oldData;
          const copiedData = cloneDeep(oldData);
          for (const page of copiedData.pages) {
            const allocationBlock = page
              .find(({ id }) => id === activeResourceId)
              ?.projects?.find((prj) => prj.id === activeProjectId)
              ?.timeblocks?.find(({ id }) => id === activeBlockIds[0]);
            if (allocationBlock) allocationBlock.note = block?.note;
          }
          return copiedData;
        },
      );
    },
    [
      activeBlockIds,
      activeProjectId,
      activeResourceId,
      queryClient,
      workspaceId,
    ],
  );

  return block ? (
    <div
      ref={ref}
      style={{ left: left - 4, width: width + 8 }}
      className={classNames(styles.container, {
        [styles.isMulti]: activeBlocks.length > 1,
        [styles.timeOff]: type === PROJECT_TYPE.TIME_OFF,
      })}
      onMouseDown={handleMouseDown}
      aria-hidden
    >
      {transition((style, isOpen) => (
        <span>
          {isOpen && (
            <animated.div
              className={styles.actionsWrapper}
              style={{ opacity: style.opacity }}
            >
              <div className={styles.actions} style={{ left: leftPadding + 4 }}>
                <BlockActions
                  capacity={capacity}
                  block={block}
                  onClose={onCloseActions}
                  onCopy={console.log}
                  onCut={console.log}
                  onPaste={console.log}
                  onDelete={() =>
                    onDeleteBlockFn(activeBlocks.map((b) => b.id))
                  }
                  value={
                    activeBlocks.length > 1
                      ? 'Mix'
                      : (block?.allocation?.toString() ?? '')
                  }
                  onUpdateDetail={onChangeBlockDetailFn}
                  onChange={onChangeBlocksAllocationFn}
                />
              </div>
            </animated.div>
          )}
        </span>
      ))}
    </div>
  ) : null;
}
