import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { ACCOUNT_QUERY_KEY } from '@/hooks/account/useAccountQuery';
import { http } from '@/services/api';
import { BaseResponse } from '@/types/base-responses';
import { TUserAccount } from '@/types/workspace';



export type CustomerCreateType = {
  customerId: string;
  addressId?: string;
  businessId?: string;
};

export default function useCreateCustomer() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation({
    mutationFn: async ({
      customerId,
      addressId,
      businessId,
    }: CustomerCreateType) => {
      const {
        data: { data },
      } = await http.post<BaseResponse<CustomerCreateType>>(
        `/workspaces/${workspaceId}/customer`,
        {
          customerId,
          addressId,
          businessId,
        },
      );
      return data;
    },
    onMutate: async (body: CustomerCreateType) => {
      const accountData = queryClient.getQueryData<TUserAccount>([
        ACCOUNT_QUERY_KEY,
      ]);
      const dt = cloneDeep(accountData);
      const ws = dt?.workspaces.find((w) => w.id === workspaceId);
      if (ws) ws.customer = body;
      queryClient.setQueryData<TUserAccount>([ACCOUNT_QUERY_KEY], dt);
      return { accountData };
    },
    onError: (error: Error, variables, context) => {
      queryClient.setQueryData<TUserAccount>(
        [ACCOUNT_QUERY_KEY],
        context?.accountData,
      );
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
