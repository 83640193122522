import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { useQueryClient } from '@tanstack/react-query';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import { noop } from 'lodash';

import { TIMELINE_RESOURCE_QUERY_KEY } from '@/hooks/workspace/resources/useTimelineResourceQuery';
import { PROJECT_STATUS } from '@/types/enums';
import { TLabel } from '@/types/labels';
import {
  TFiltersProject,
  TimelineFilters,
  TResourceSearchResult,
} from '@/types/timeline';

type CtxData = {
  sortByName: boolean;
  setSortByName: React.Dispatch<React.SetStateAction<boolean>>;
  filters?: TimelineFilters;
  updateFilters: <T extends TimelineKeys>({
    key,
    action,
    value,
  }: ActionType<T>) => void;
  clearFilters: () => void;
};

const SortAndFilterContext = createContext<CtxData>({
  filters: {
    labels: [],
    resources: [],
    projects: [],
    status: [],
  },
  sortByName: false,
  setSortByName: noop,
  updateFilters: noop,
  clearFilters: noop,
});

export type TimelineKeys = keyof TimelineFilters & string;

export type ClearType = { action: 'CLEAR_ALL' };
export type ActionType<T extends TimelineKeys> = T extends 'labels'
  ? { action: 'ADD' | 'REMOVE' | 'CLEAR'; key: 'labels'; value: TLabel }
  : T extends 'projects'
    ? {
        action: 'ADD' | 'REMOVE' | 'CLEAR';
        key: 'projects';
        value: TFiltersProject;
      }
    : T extends 'resources'
      ? {
          action: 'ADD' | 'REMOVE' | 'CLEAR';
          key: 'resources';
          value: TResourceSearchResult;
        }
      : T extends 'status'
        ? {
            action: 'ADD' | 'REMOVE' | 'CLEAR';
            key: 'status';
            value: PROJECT_STATUS;
          }
        : never;

function filtersReducer(
  state: TimelineFilters,
  action: ActionType<TimelineKeys> | ClearType,
) {
  if (action.action === 'CLEAR_ALL') {
    return {
      projects: [],
      resources: [],
      labels: [],
      status: [],
    };
  }
  return {
    ...state,
    [action.key]:
      action.action === 'ADD'
        ? [...state[action.key], action.value]
        : action.action === 'CLEAR'
          ? []
          : state[action.key].filter((item) => {
              if (action.key === 'status') {
                return item !== action.value;
              } else
                return (item as typeof action.value).id !== action.value.id;
            }),
  };
}

export default function SortAndFilter({
  children,
  namePrefix,
}: {
  children: React.ReactNode;
  namePrefix: string;
}) {
  const [localFilters, setLocalFilters] = useLocalStorage<TimelineFilters>(
    // `${namePrefix}-filters`,
    `timeline-filters`,
    {
      projects: [],
      resources: [],
      labels: [],
      status: [],
    },
  );

  const [sortedByName, setSortedByName] = useLocalStorage<boolean>(
    `${namePrefix}-sort-by-name`,
    false,
  );

  const [filters, dispatchFilters] = useReducer(
    filtersReducer,
    localFilters ?? {
      projects: [],
      resources: [],
      labels: [],
      status: [],
    },
  );

  useEffect(() => {
    if (setLocalFilters && filters) setLocalFilters(filters);
  }, [filters, setLocalFilters]);

  const clearFilters = useCallback(() => {
    dispatchFilters({ action: 'CLEAR_ALL' });
  }, []);

  const queryClient = useQueryClient();

  const setSortByName: React.Dispatch<React.SetStateAction<boolean>> =
    useCallback(
      (sorted) => {
        setSortedByName(sorted);
        queryClient.invalidateQueries({
          queryKey: [TIMELINE_RESOURCE_QUERY_KEY],
        });
      },
      [queryClient, setSortedByName],
    );

  return (
    <SortAndFilterContext.Provider
      value={{
        filters,
        sortByName: sortedByName ?? false,
        setSortByName,
        updateFilters: dispatchFilters,
        clearFilters,
      }}
    >
      {children}
    </SortAndFilterContext.Provider>
  );
}

export const useSortAndFilter = () => {
  const ctx = useContext(SortAndFilterContext);
  if (!ctx) {
    throw new Error(
      'useSortAndFilter must be used within a SortAndFilterContext',
    );
  }
  return ctx;
};
