import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { useSortAndFilter } from '@/contexts/SortAndFilterContext';
import { UserContext } from '@/contexts/UserContext';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@/hooks/workspace/resources/useTimelineResourceQuery';
import { updateProjectStatus } from '@/services/api/workspace/projects';
import { PROJECT_STATUS } from '@/types/enums';

import {
  ContextData,
  onUpdateProjectMutate,
} from './mutationsFunctions/onUpdateProjectMutate';
import { COMPLETED_PROJECT_QUERY } from './useCompletedProject';
import { PROJECT_COUNT_BY_STATUS_QUERY_KEY } from './useProjectCountByStatus';
import { TIMELINE_PROJECT_QUERY_KEY } from './useTimelineProjectsQuery';

type Props = {
  projectId: string;
  status: PROJECT_STATUS;
};

export function useUpdateProjectStatus() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  const { sortByName, filters } = useSortAndFilter();
  return useMutation<void, Error, Props>({
    mutationFn: async (prj: Props): Promise<void> => {
      await updateProjectStatus({ workspaceId, ...prj });
    },
    onMutate: (prj) =>
      onUpdateProjectMutate(
        queryClient,
        workspaceId!,
        sortByName ?? false,
        filters,
        prj,
      ),
    onSuccess: (_data, variables, context) => {
      const ctx = context as ContextData;
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      if (
        (ctx.hasNextPage !== undefined &&
          ctx.hasNextPage !== null &&
          ctx.hasNextPage) ||
        variables.status === PROJECT_STATUS.COMPLETED
      )
        queryClient.invalidateQueries({
          queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY, workspaceId],
        });
      queryClient.invalidateQueries({ queryKey: [TIMELINE_PROJECT_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [TIMELINE_RESOURCE_QUERY_KEY],
      });
      if (variables.status === PROJECT_STATUS.COMPLETED) {
        queryClient.invalidateQueries({ queryKey: [COMPLETED_PROJECT_QUERY] });
        queryClient.invalidateQueries({
          queryKey: [
            PROJECT_COUNT_BY_STATUS_QUERY_KEY,
            workspaceId,
            PROJECT_STATUS.COMPLETED,
          ],
        });
      }
    },
    onError: (error: Error, _variables, context) => {
      const ctx = context as ContextData;
      if (!ctx?.oldStatus || !ctx?.newStatus) return;
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
