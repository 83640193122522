import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';

import { UserContext } from '@/contexts/UserContext';
import { handleApiCall } from '@/services/api';
import { getHidden } from '@/services/api/workspace/projects';
import { TSuccessErrorHook } from '@/types/generic';
import { THiddenProject } from '@/types/timeline';

export const GET_HIDDEN_QUERY = 'get-hidden';
export default function useHiddenQuery({
  query,
  enabled,
  resourceId,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<THiddenProject[]> & {
  query: string;
  resourceId: string;
}) {
  const { workspaceId } = useContext(UserContext);

  return useQuery({
    queryKey: [GET_HIDDEN_QUERY, workspaceId, query, resourceId],
    queryFn: () =>
      handleApiCall(
        getHidden({ resourceId, query, workspaceId }),
        onSuccess,
        onError,
      ),
    enabled: enabled && !!workspaceId,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
}
