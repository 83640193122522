import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import { addWeeks, endOfWeek, isWithinInterval } from 'date-fns';
import { find, some } from 'lodash';
import { isMobile } from 'react-device-detect';

import { useEventEmitter } from '@/contexts/EventEmitterContext';
import { UIContext } from '@/contexts/UIContext';
import { generateUUID } from '@/services/helpers';
import {
  updateBlocksWithProperEvents,
} from '@/services/helpers/timelines/resources';
import { ALLOCATION_EVENT_OPERATION_TYPE } from '@/types/enums';
import { TTimeBlockRange } from '@/types/timeline';

import ScrollDraggingOverlay from '@/components/Timelines/TimelineResources/DraggingOverlay';
import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import { useProjectRowContext } from './Context';
import styles from './styles.module.css';

export default function Content({ children }: PropsWithChildren) {
  const {
    project,
    resource,
    mouseEventRef,
    setNewBlock,
    setHoverWeek,
    onActiveBlockFn,
    rowDisabled,
  } = useProjectRowContext();

  const {
    virtualizer,
    timeInterval,
    weekSizeInPx,
    setBlocksToUpdate,
    activeBlockIds,
  } = useContext(TimelineResourcesContext);

  const { layoutIsExpanded } = useContext(UIContext);
  const mousePositionRef = useRef<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const { next } = useEventEmitter<Date | undefined>('hoverWeek');

  const currentTimeIntervalStart = useRef<Date | null>(null);

  const handleMouseDownFn = useCallback(
    (event: React.MouseEvent, projectId: string) => {
      if (rowDisabled || event.button !== 0) return;
      const mousePosition = event.clientX + (virtualizer.scrollOffset ?? 0);
      mousePositionRef.current = event.clientX;
      // Snap
      const startWeek = addWeeks(
        timeInterval.start,
        Math.floor(mousePosition / weekSizeInPx),
      );
      const endWeek = endOfWeek(startWeek);
      const slotHasBlock = resource?.projects?.some(
        (project) =>
          project.id === projectId &&
          some(project.timeblocks, (block) =>
            isWithinInterval(startWeek, {
              start: block.start,
              end: block.end,
            }),
          ),
      );

      if (!slotHasBlock && activeBlockIds?.length === 0) {
        event.stopPropagation();
        mouseEventRef.current = {
          ...mouseEventRef.current,
          mouseDownX: event.clientX + (virtualizer.scrollOffset ?? 0),
          projectId,
          newInterval: {
            start: startWeek,
            end: endWeek,
          },
        };
        setNewBlock({
          id: 'new',
          allocation: 1,
          start: startWeek,
          end: endWeek,
        });
      }
      currentTimeIntervalStart.current = timeInterval.start;
      setIsDragging(true);
    },
    [
      rowDisabled,
      virtualizer.scrollOffset,
      timeInterval.start,
      weekSizeInPx,
      resource?.projects,
      activeBlockIds?.length,
      mouseEventRef,
      setNewBlock,
    ],
  );

  const handleDragging = useCallback(() => {
    if (isMobile) return;
    if (!mousePositionRef.current) return;
    const mousePosition =
      mousePositionRef.current + (virtualizer.scrollOffset ?? 0);
    if (isDragging) {
      // Click & drag creation of a new block
      if (mouseEventRef.current.mouseDownX) {
        const startPosition = Math.min(
          mouseEventRef.current.mouseDownX,
          mousePosition,
        );
        const endPosition = Math.max(
          mouseEventRef.current.mouseDownX,
          mousePosition,
        );
        // Snap
        const startWeek = addWeeks(
          timeInterval.start,
          Math.floor(startPosition / weekSizeInPx),
        );

        const endWeek = endOfWeek(
          addWeeks(
            currentTimeIntervalStart.current || timeInterval.start,
            Math.floor(endPosition / weekSizeInPx),
          ),
        );
        // Create new block
        mouseEventRef.current.newInterval = {
          start: startWeek,
          end: endWeek,
        };
        if (mouseEventRef.current.newInterval) {
          setNewBlock({
            ...mouseEventRef.current.newInterval,
            id: 'new',
            allocation: 1,
          });
        }
      }
    }
  }, [
    virtualizer.scrollOffset,
    isDragging,
    mouseEventRef,
    timeInterval.start,
    weekSizeInPx,
    setNewBlock,
  ]);

  const handleMoveAndDrag = useCallback(
    (e: { clientX: number }) => {
      mousePositionRef.current = e.clientX;
      handleDragging();
    },
    [handleDragging],
  );

  const handleMouseMoveFn = useCallback(
    ({ clientX }: { clientX: number }) => {
      if (isMobile) return;
      // determine the week that the mouse is hovering
      mousePositionRef.current = clientX;
      const mousePosition = clientX + (virtualizer.scrollOffset ?? 0);
      // Simple hovering
      const addedWeek = addWeeks(
        timeInterval.start,
        Math.floor(mousePosition / weekSizeInPx),
      );
      setHoverWeek(addedWeek);
      next?.(addedWeek);
    },
    [
      virtualizer.scrollOffset,
      next,
      setHoverWeek,
      timeInterval.start,
      weekSizeInPx,
    ],
  );
  const handleMouseExitFn = useCallback(() => {
    if (isMobile) return;

    next?.(undefined);
  }, [next]);

  const handleMouseUpFn = useCallback(() => {
    mousePositionRef.current = null;
    if (isMobile) return;
    if (mouseEventRef?.current?.newInterval) {
      const id = generateUUID();
      const newBlock = {
        ...mouseEventRef?.current?.newInterval,
        id,
        allocation: 1,
        operation: ALLOCATION_EVENT_OPERATION_TYPE.INSERT,
      } as TTimeBlockRange;

      if (mouseEventRef?.current?.projectId) {
        onActiveBlockFn({
          blockId: newBlock?.id,
          isMulti: false,
          projectId: mouseEventRef?.current?.projectId,
          resourceId: resource?.id ?? '',
        });
        const currentProject = find(resource?.projects, {
          id: mouseEventRef?.current?.projectId,
        });

        const blocksToUpdate = [
          ...updateBlocksWithProperEvents(
            currentProject?.timeblocks || [],
            newBlock,
          ),
          newBlock,
        ];

        setBlocksToUpdate({
          events: blocksToUpdate,
          projectId: mouseEventRef?.current?.projectId,
          resourceId: resource?.id ?? '',
          shouldInvalidated: true,
        });
      }
    }
    mouseEventRef.current = {};
    setNewBlock(null);
    currentTimeIntervalStart.current = null;
    setIsDragging(false);
  }, [
    mouseEventRef,
    onActiveBlockFn,
    resource,
    setBlocksToUpdate,
    setNewBlock,
  ]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mouseup', handleMouseUpFn);
      document.addEventListener('contextmenu', handleMouseUpFn);
      document.addEventListener('mousemove', handleMoveAndDrag);
    }
    return () => {
      document.removeEventListener('mouseup', handleMouseUpFn);
      document.removeEventListener('contextmenu', handleMouseUpFn);
      document.removeEventListener('mousemove', handleMoveAndDrag);
    };
  }, [handleMouseUpFn, isDragging, handleMoveAndDrag]);

  return (
    <>
      <div
        style={{ zIndex: 1 }}
        className={classNames(styles.content, {
          [styles.isExpanded]: layoutIsExpanded,
        })}
        onMouseLeave={handleMouseExitFn}
        onMouseMove={handleMouseMoveFn}
        onMouseDown={(event) => handleMouseDownFn(event, project?.id ?? '')}
        aria-hidden
      >
        {children}
      </div>
      <ScrollDraggingOverlay
        isDragging={isDragging}
        panelId={`project-row`}
        stepSize={weekSizeInPx}
        onScrollUpdate={handleDragging}
      />
    </>
  );
}
