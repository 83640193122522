import * as Tooltip from '@radix-ui/react-tooltip';
import { IconLink } from '@tabler/icons-react';
import classNames from 'classnames';
import { Emoji } from 'emoji-picker-react';
import { t } from 'i18next';

import { getCssVariable } from '@/services/helpers';
import { PROJECT_COLOR, PROJECT_TYPE } from '@/types/enums';

import styles from './styles.module.css';

type Props = {
  color?: PROJECT_COLOR;
  emoji?: string;
  type: PROJECT_TYPE;
  name?: string;
  size?: number;
  emojiSize?: number;
  radius?: number;
  isShared?: boolean;
  className?: string;
};
const iconColor = getCssVariable('--text-subdued');

export default function ProjectNameWithEmoji({
  color,
  emoji,
  type,
  name,
  size = 24,
  emojiSize = 16,
  radius = 6,
  isShared = false,
  className,
}: Props) {
  return (
    <>
      <span
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: `${radius}px`,
        }}
        className={classNames(
          styles.shade,
          className ?? '',
          styles[`shade-${color}`],
        )}
      >
        <Emoji unified={emoji ?? ''} size={emojiSize} />
      </span>
      {name && (
        <>
          <p className={styles.text}>
            {type === PROJECT_TYPE.TIME_OFF ? t('common:timeOff') : name}
          </p>
          {isShared ? (
            <Tooltip.Provider>
              <Tooltip.Root delayDuration={200}>
                <Tooltip.Trigger asChild>
                  <div>
                    <IconLink size={16} color={iconColor} />
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content className="TooltipContent" sideOffset={5}>
                    <span>Public access</span>
                    <Tooltip.Arrow className="TooltipArrow" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          ) : null}
        </>
      )}
    </>
  );
}
