import { ComponentProps, PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './styles.module.css';
type Props = {
  direction?: 'row' | 'column';
  align?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'stretch';
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'stretch';
  className?: string;
  style?: React.CSSProperties;
  gap?: number | string;
} & ComponentProps<'div'>;
export default function FlexContainer({
  direction,
  align,
  justify,
  gap,
  style,
  children,
  className,
  ...others
}: PropsWithChildren<Props>) {
  return (
    <div
      className={classNames(styles.flexContainer, className)}
      style={{
        flexDirection: direction ?? 'row',
        alignItems: align ?? 'flex-start',
        justifyContent: justify ?? 'flex-start',
        gap:
          gap !== undefined && gap !== null
            ? typeof gap === 'string'
              ? gap
              : `${gap}px`
            : '.5rem',
        ...(style ?? {}),
      }}
      {...(others ?? {})}
    >
      {children}
    </div>
  );
}
