import { IconHourglassOff } from '@tabler/icons-react';
import { t } from 'i18next';

import { ROUTES } from '@/types/routes';

import Button from '@/components/Button';

import { ErrorScreen } from '..';
import styles from './styles.module.css';

export default function InvitationExpiredError409() {
  return (
    <ErrorScreen>
      <div className={styles.wrapper}>
        <IconHourglassOff className={styles.icon} size={64} />
        <div className={styles.content}>
          <h1 className={styles.head}>{t('common:invitationExpired.title')}</h1>
          <p>{t('common:invitationExpired.description')}</p>
        </div>
        <div className={styles.cta}>
          <Button
            label={t('common:invitationExpired.ctaLabel')}
            size="large"
            link={{
              to: ROUTES.ROOT,
              preventScrollReset: false,
            }}
          />
        </div>
      </div>
    </ErrorScreen>
  );
}
