import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@/hooks/insights/useInsightsPartQuery';
import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '@/hooks/workspace/projects/useProjectListWithResourcesStatusQuery';
import { SEARCH_PROJECTS_KEY } from '@/hooks/workspace/projects/useProjectsQuery';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { addNewProject } from '@/services/api/workspace/projects';
import { PROJECT_COLOR, PROJECT_STATUS } from '@/types/enums';
import { TResponseError } from '@/types/generic';

import { COMPLETED_PROJECT_QUERY } from './useCompletedProject';
import { PROJECT_COUNT_BY_STATUS_QUERY_KEY } from './useProjectCountByStatus';

type Props = {
  name: string;
  resourceId?: string;
  color: PROJECT_COLOR;
  emoji?: string;
  status?: PROJECT_STATUS;
};

export default function useAddNewProject() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation<void, TResponseError, Props>({
    mutationFn: async ({
      name,
      resourceId,
      color,
      emoji,
      status,
    }: Props): Promise<void> => {
      await addNewProject({
        workspaceId,
        name,
        resourceId,
        color,
        emoji,
        status,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RESOURCES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [SEARCH_PROJECTS_KEY] });
      queryClient.invalidateQueries({ queryKey: [INSIGHT_PART_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY],
      });
      queryClient.invalidateQueries({ queryKey: [COMPLETED_PROJECT_QUERY] });
      queryClient.invalidateQueries({
        queryKey: [PROJECT_COUNT_BY_STATUS_QUERY_KEY],
      });
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
