import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './styles.module.css';
type SectionProps = {
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  className?: string;
};

export default function Section(props: PropsWithChildren<SectionProps>) {
  return (
    <div className={classNames(styles.container, props.className ?? '')}>
      {(props.title || props.subTitle) && (
        <div className={styles.titleContainer}>
          {props.title && <div className={styles.title}>{props.title}</div>}
          {props.subTitle && (
            <div className={styles.subTitle}>{props.subTitle}</div>
          )}
        </div>
      )}
      <div className={styles.content}>{props.children}</div>
    </div>
  );
}
