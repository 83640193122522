import {
  ForwardedRef,
  Fragment,
  PropsWithChildren,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useRef,
} from 'react';

import { Item, Menu, useContextMenu } from 'react-contexify';
import type { ItemProps, MenuProps } from 'react-contexify';
import type { Options } from 'react-hotkeys-hook';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';

import { GLOBAL_SCOPE } from '@/types/constants';

import Portal from '../Portal';

type ShortcutContextType = {
  scope: string;
};

export const ShortcutContext = createContext<ShortcutContextType>({
  scope: '*',
});

type ShortcutItemProps = Omit<ItemProps, 'keyMatcher' | 'handlerEvent'> & {
  keyMatch: string | readonly string[];
  keyHandler: () => void;
  keyOptions?: Options;
};

function ShortcutLabelItem({
  children,
  ...props
}: PropsWithChildren<
  Omit<ItemProps, 'keyMatcher' | 'handlerEvent' | 'onClick' | 'disabled'>
>) {
  return (
    <Item {...props} disabled={true}>
      {children}
    </Item>
  );
}

function ShortcutItem(
  {
    children,
    keyHandler,
    keyMatch,
    keyOptions,
    ...props
  }: PropsWithChildren<ShortcutItemProps>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const context = useContext(ShortcutContext);
  if (!context) {
    throw new Error('ShortcutItem must be used inside ShortcutMenu');
  }
  const { scope } = context;
  const { hideAll } = useContextMenu({ id: scope });
  const { disableScope, enableScope } = useHotkeysContext();

  const handleOnClick = useCallback(() => {
    if (props.closeOnClick ?? true) {
      enableScope(GLOBAL_SCOPE);
      disableScope(`${scope}`);
      hideAll();
    }
    keyHandler();
  }, [
    props.closeOnClick,
    keyHandler,
    scope,
    enableScope,
    disableScope,
    hideAll,
  ]);

  useHotkeys(keyMatch, handleOnClick, {
    preventDefault: true,
    scopes: scope,
    enabled:
      props.disabled !== undefined && props.disabled !== null
        ? !props.disabled
        : true,
    ...(keyOptions ?? {}),
  });

  return (
    <div
      style={{ display: 'flex', width: '100%', alignItems: 'center' }}
      ref={ref}
    >
      <Item {...props} onClick={handleOnClick} style={{ width: '100%' }}>
        {children}
      </Item>
    </div>
  );
}

function ShortcutMenu({
  children,
  onVisibilityChange,
  withPortal = true,
  id,
  ...props
}: PropsWithChildren<MenuProps> & { withPortal?: boolean }) {
  const { disableScope, enableScope } = useHotkeysContext();
  const Parent = withPortal ? Portal : Fragment;
  const isMenuOpen = useRef<boolean>(false);

  const onVisibilityChangeHandler = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        enableScope(`${id}`);
      } else if (isMenuOpen.current) {
        disableScope(`${id}`);
      }
      onVisibilityChange?.(isVisible);
      isMenuOpen.current = isVisible;
    },
    [disableScope, enableScope, id, onVisibilityChange],
  );

  return (
    <Parent>
      <Menu
        className="is-right"
        id={id}
        {...props}
        onVisibilityChange={onVisibilityChangeHandler}
      >
        <ShortcutContext.Provider value={{ scope: `${id}` }}>
          {children}
        </ShortcutContext.Provider>
      </Menu>
    </Parent>
  );
}

ShortcutMenu.Item = forwardRef(ShortcutItem);
ShortcutMenu.LabelItem = ShortcutLabelItem;
export default ShortcutMenu;
