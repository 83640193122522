import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import Button from '@components/Button';
import Portal from '@components/Portal';
import * as Tooltip from '@radix-ui/react-tooltip';
import {
  IconClipboard,
  IconCopy,
  IconNote,
  IconScissors,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { t } from 'i18next';
import { isEmpty, noop } from 'lodash';
import { Item, Menu, RightSlot } from 'react-contexify';
import { useDebounce } from 'use-hooks';

import { TTimeBlockRange } from '@/types/timeline';

import AllocationNoteForm from './AllocationNoteForm';
import { ItemWithTooltip } from './ItemWithTooltip';
import styles from './styles.module.css';

type Props = {
  block: TTimeBlockRange;
  capacity: number;
  value: string;
  onChange: (value: string) => void;
  onCopy: () => void;
  onCut: () => void;
  onPaste: () => void;
  onDelete: () => void;
  onClose: () => void;
  onUpdateDetail: (block: TTimeBlockRange | undefined) => void;
};

function hasOneDecimalValue(value: string) {
  const decimalCount = (value.toString().split('.')[1] || []).length;
  if (decimalCount > 1) {
    return false;
  } else {
    return true;
  }
}

function isValidValue(value: number, max: number) {
  if (isNaN(Number(value))) return false;
  if (typeof value !== 'number') {
    return false;
  }

  if (!hasOneDecimalValue(String(value))) return;
  if (value === 0 || value > max) {
    return false;
  }

  return true;
}
export type ItemWithTooltipProps = {
  shortcut?: string;
  shortcutLabel?: string;
};
export default function BlockActions({
  block,
  value,
  onChange,
  capacity,
  onCopy,
  onDelete,
  onCut,
  onClose,
  onPaste,
  onUpdateDetail = noop,
}: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [noteActive, setNoteActive] = useState(false);
  const [currentInputValue, setCurrentInputValue] = useState<string>(value);
  const debouncedValue = useDebounce(currentInputValue, 500);
  const [_menuIsOpen, setMenuIsOpen] = useState(false);
  const onInputChangeFn = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.replace(',', '.');
    if (isEmpty(value)) setCurrentInputValue('');
    if (isNaN(Number(value))) {
      // setCurrentInputValue('');
      ref.current?.select();
      return;
    }
    if (!hasOneDecimalValue(value)) return;
    setCurrentInputValue(value);
  };

  useEffect(() => {
    if (!isValidValue(Number(debouncedValue), Number(capacity))) return;
    if (Number(debouncedValue) !== Number(value) && !isEmpty(debouncedValue)) {
      onChange(debouncedValue);
    }
  }, [capacity, debouncedValue, onChange, value]);

  useEffect(() => {
    setCurrentInputValue((prevValue) =>
      Number(prevValue) !== Number(value) ? value : prevValue,
    );
    setTimeout(() => {
      if (ref?.current !== document.activeElement) {
        ref?.current?.select();
      }
    }, 0);
  }, [value]);

  const matchCopyShortCut = (e: KeyboardEvent): boolean => {
    return e.metaKey && e.key === 'c';
  };

  const matchCutShortCut = (e: KeyboardEvent): boolean => {
    return e.metaKey && e.key === 'x';
  };

  const matchPasteShortCut = (e: KeyboardEvent): boolean => {
    return e.metaKey && e.key === 'v';
  };

  const onSubmitFn = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!isValidValue(Number(currentInputValue), Number(capacity))) return;
      onChange(currentInputValue);
      onClose();
    },
    [capacity, currentInputValue, onChange, onClose],
  );

  const onKeyEntered = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Escape') onClose();
      else if (!noteActive && key === 'Delete') onDelete();
    },
    [noteActive, onClose, onDelete],
  );

  const onNotePressed = useCallback(
    (e: KeyboardEvent) => {
      const { key } = e;
      if (!noteActive && (key === 'n' || key === 'N')) {
        e.preventDefault();
        e.stopPropagation();
        setNoteActive(true);
      }
    },
    [noteActive],
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyEntered);
    if (!noteActive) window.addEventListener('keydown', onNotePressed);

    return () => {
      window.removeEventListener('keydown', onKeyEntered);
      window.removeEventListener('keydown', onNotePressed);
    };
  }, [noteActive, onKeyEntered, onNotePressed]);

  const handleUpdateDetail = useCallback(
    (block?: TTimeBlockRange) => {
      if (block) onUpdateDetail(block);
    },
    [onUpdateDetail],
  );

  const handleClose = useCallback(() => {
    setNoteActive(false);
    setTimeout(() => {
      if (ref?.current !== document.activeElement) {
        ref?.current?.select();
      }
    }, 0);
  }, []);

  return (
    <>
      <div
        onMouseMove={(e) => e.stopPropagation()}
        className={classNames(styles.container, {
          [styles.noteInputActive]: noteActive,
        })}
      >
        {noteActive ? (
          <AllocationNoteForm
            block={block}
            onClose={handleClose}
            onUpdateDetail={handleUpdateDetail}
          />
        ) : (
          <>
            <div
              className={classNames(styles.inputWrapper, {
                [styles.hasError]:
                  currentInputValue !== 'Mix' &&
                  !isValidValue(Number(currentInputValue), Number(capacity)),
              })}
            >
              <form onSubmit={onSubmitFn} autoCorrect="false">
                <input
                  onChange={onInputChangeFn}
                  ref={ref}
                  value={currentInputValue}
                />
                {/* <Input

                  isCenter
                  autoComplete={false}

                  error={
                    currentInputValue !== 'Mix' &&
                    !isValidValue(Number(currentInputValue), Number(capacity))
                  }
                  isDark

                  name="block-actions"
                /> */}
              </form>
            </div>

            <span className={styles.separator}></span>
            <Tooltip.Provider delayDuration={0}>
              <ItemWithTooltip shortcut="N" shortcutLabel="Detail">
                <Button
                  icon={IconNote}
                  size="medium"
                  onClick={() => {
                    setNoteActive((act) => !act);
                  }}
                />
              </ItemWithTooltip>

              <span className={styles.separator}></span>
              <ItemWithTooltip shortcut="Delete" shortcutLabel="Remove">
                <Button icon={IconTrash} size="medium" onClick={onDelete} />
              </ItemWithTooltip>

              <span className={styles.separator}></span>
              <ItemWithTooltip shortcut="Esc" shortcutLabel="Deselect">
                <Button icon={IconX} size="medium" onClick={onClose} />
              </ItemWithTooltip>
            </Tooltip.Provider>
          </>
        )}
      </div>
      <Portal>
        <Menu
          onVisibilityChange={(isVisible: boolean) => setMenuIsOpen(isVisible)}
          className="top-triangle"
          animation={false}
          id={`block-context-menu-${block.id}`}
        >
          <Item onClick={onCopy} keyMatcher={matchCopyShortCut}>
            <IconCopy size={20} style={{ marginRight: 8 }} />{' '}
            {t('common:blockContextMenuActions.copy')} <RightSlot>⌘C</RightSlot>{' '}
          </Item>
          <Item onClick={onCut} keyMatcher={matchCutShortCut}>
            <IconScissors size={20} style={{ marginRight: 8 }} />{' '}
            {t('common:blockContextMenuActions.cut')} <RightSlot>⌘X</RightSlot>{' '}
          </Item>
          <Item onClick={onPaste} disabled keyMatcher={matchPasteShortCut}>
            <IconClipboard size={20} style={{ marginRight: 8 }} />{' '}
            {t('common:blockContextMenuActions.paste')}{' '}
            <RightSlot>⌘V</RightSlot>{' '}
          </Item>
        </Menu>
      </Portal>
    </>
  );
}
