import { ElementType, useMemo } from 'react';

import { IconX } from '@tabler/icons-react';

import { TimelineKeys, useSortAndFilter } from '@/contexts/SortAndFilterContext';
import { titleCase } from '@/services/helpers';
import { PROJECT_TYPE } from '@/types/enums';
import { TLabel } from '@/types/labels';
import { TimelineFilters } from '@/types/timeline';

import Avatar from '@/components/Avatar';
import Button from '@/components/Button';
import { StatusIndicator } from '@/components/Indicators';
import Portal from '@/components/Portal';
import ProjectNameWithEmoji from '@/components/ProjectNameWithEmoji';

import styles from './styles.module.css';
import LabelsIndicator from '../../common/LabelsIndicator';

function Separator() {
  return (
    <div className={styles.separator}>
      <span></span>
    </div>
  );
}

type IndicatorsProps<T extends TimelineKeys> = {
  items: TimelineFilters[T];
};

function LabelFiltersIndicator({ items }: IndicatorsProps<'labels'>) {
  const item: TLabel = items?.[0];
  return (
    item && (
      <div className={styles.filterItem}>
        <LabelsIndicator
          withTooltip={false}
          dotClassName={styles.dotColorBorder}
          resource={{
            id: `labels-${items.length}`,
            labels: items.slice(0, 3),
          }}
        />
        <span className={styles.filterText}>
          {items.length > 1 ? `${items.length} labels` : item.value}
        </span>
      </div>
    )
  );
}

function ResourcesFiltersIndicator({ items }: IndicatorsProps<'resources'>) {
  const item = items?.[0];
  return (
    item && (
      <div className={styles.filterItem}>
        <div className={styles.stackable}>
          {items.slice(0, 3).map((resource, idx) => (
            <span
              key={resource.id}
              style={{ marginLeft: idx * 12 }}
              className={styles.stackableCell}
            >
              <Avatar
                key={resource.id}
                size={'xsmall'}
                image={resource.image?.ref}
                name={`${resource.firstName} ${resource.lastName}`}
              />
            </span>
          ))}
        </div>
        <span className={styles.filterText}>
          {items.length > 1
            ? `${items.length} resources`
            : `${item.firstName} ${item.lastName}`}
        </span>
      </div>
    )
  );
}

function ProjectsFiltersIndicator({ items }: IndicatorsProps<'projects'>) {
  const item = items?.[0];
  return (
    item && (
      <div className={styles.filterItem}>
        <div className={styles.stackable}>
          {items.slice(0, 3).map((item, idx) => (
            <div
              key={item.id}
              className={styles.stackableCell}
              style={{ marginLeft: idx * 12 }}
            >
              <ProjectNameWithEmoji
                type={PROJECT_TYPE.PROJECT}
                color={item.color}
                emoji={item.emoji}
                emojiSize={12}
                size={16}
                className={styles.wrapper}
              />
            </div>
          ))}
        </div>
        <span className={styles.filterText}>
          {items.length > 1 ? `${items.length} projects` : item.name}
        </span>
      </div>
    )
  );
}

function StatusFiltersIndicator({ items }: IndicatorsProps<'status'>) {
  const item = items?.[0];
  return (
    item && (
      <div key={item} className={styles.filterItem}>
        <div className={styles.stackable}>
          {items.slice(0, 3).map((status, idx) => (
            <div
              key={status}
              className={styles.stackableCell}
              style={{ marginLeft: idx * 12 }}
            >
              <StatusIndicator type={status} size={16} showTooltip={false} />
            </div>
          ))}
        </div>
        <span className={styles.filterText}>
          {items.length > 1 ? `${items.length} statuses` : titleCase(item)}
        </span>
      </div>
    )
  );
}

type FiltersIndicatorsComponentsType<T extends TimelineKeys> = Record<
  T,
  ElementType<IndicatorsProps<TimelineKeys>>
>;

const FiltersIndicatorsComponents = {
  labels: { order: 1, component: LabelFiltersIndicator },
  resources: { order: 2, component: ResourcesFiltersIndicator },
  projects: { order: 3, component: ProjectsFiltersIndicator },
  status: { order: 4, component: StatusFiltersIndicator },
};

const reduceElementWithSeparator = (
  acc: JSX.Element[],
  currentElement: JSX.Element,
  currentIdx: number,
  _array: JSX.Element[],
): JSX.Element[] => {
  return [
    ...(currentIdx ? [<Separator key={`separator-${currentIdx}`} />] : []),
    currentElement,
    ...acc,
  ];
};

export default function FiltersIndicator() {
  const { filters, clearFilters } = useSortAndFilter();

  const showFiltersIndicator = useMemo(() => {
    return filters && Object.values(filters)?.some((filter) => filter.length);
  }, [filters]);

  const filtersElements = useMemo(() => {
    if (!filters) return [];
    return Object.entries(filters)
      .filter(([_key, value]) => Boolean(value.length))
      .sort(([keyA], [keyB]) => {
        return (
          FiltersIndicatorsComponents[keyA as TimelineKeys].order -
          FiltersIndicatorsComponents[keyB as TimelineKeys].order
        );
      })
      .map(([key, value]) => {
        const k = key as TimelineKeys;
        const Component = FiltersIndicatorsComponents[key as TimelineKeys]
          .component as FiltersIndicatorsComponentsType<TimelineKeys>[typeof k];
        return <Component key={key} items={value} />;
      })
      .reduceRight(reduceElementWithSeparator, []);
  }, [filters]);

  return (
    !!showFiltersIndicator && (
      <Portal>
        <div className={styles.filtersIndicatorContainer}>
          <div className={styles.filtersContainer}>
            {filters && (
              <>
                {filtersElements}
                <Separator />
                <Button
                  icon={IconX}
                  iconProps={{ size: 20 }}
                  variant="ghost"
                  size="small"
                  onClick={clearFilters}
                />
              </>
            )}
          </div>
        </div>
      </Portal>
    )
  );
}
