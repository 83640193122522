import { useContext } from 'react';

import type { InfiniteData, QueryClient } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { differenceInWeeks } from 'date-fns';
import { cloneDeep, find } from 'lodash';

import { UserContext } from '@/contexts/UserContext';
import { INSIGHT_PART_QUERY_KEY } from '@/hooks/insights/useInsightsPartQuery';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { TIMELINE_RESOURCE_QUERY_KEY } from '@/hooks/workspace/resources/useTimelineResourceQuery';
import { updateAllocation } from '@/services/api/workspace/resources';
import { ALLOCATION_EVENT_OPERATION_TYPE } from '@/types/enums';
import {
  TAllocationEvent,
  TimelineFilters,
  TTimeBlockRange,
  TTimeInterval,
  TTimelineAllocationResponse,
  TTimelineResource,
} from '@/types/timeline';

import { PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY } from '../projects/useProjectListWithResourcesStatusQuery';
import { TIMELINE_PROJECT_QUERY_KEY } from '../projects/useTimelineProjectsQuery';
import { USAGE_INFO_QUERY_KEY } from '../useUsageInfo';

type Props = {
  resourceId: string;
  projectId: string;
  events: TAllocationEvent[];
  shouldInvalidate?: boolean;
};

type UseAllocationProps = {
  onError?: (error: Error) => void;
  sortByName?: boolean;
  filters?: TimelineFilters | undefined;
};

export default function useAllocation({ onError }: UseAllocationProps = {}) {
  const queryClient = useQueryClient();
  const { workspaceId } = useContext(UserContext);

  return useMutation<
    TTimelineAllocationResponse | undefined,
    Error,
    Props,
    {
      oldResources: ReturnType<QueryClient['getQueriesData']>;
      oldTimelineResource: ReturnType<QueryClient['getQueryData']>;
    }
  >({
    mutationFn: async ({
      resourceId,
      projectId,
      events,
    }: Props): Promise<TTimelineAllocationResponse | undefined> =>
      updateAllocation({
        workspaceId,
        resourceId,
        projectId,
        events,
      }),
    onMutate: (vars) => {
      try {
        const oldResources = queryClient.getQueriesData<TTimelineResource[]>({
          queryKey: [RESOURCES_QUERY_KEY, workspaceId],
          exact: false,
        });

        const oldTimelineResource = queryClient.getQueryData<
          InfiniteData<TTimelineResource[], TTimeInterval>
        >([TIMELINE_RESOURCE_QUERY_KEY, workspaceId]);

        const oldTimlineResource = queryClient.getQueryData<
          InfiniteData<TTimelineResource[]>
        >([TIMELINE_RESOURCE_QUERY_KEY, workspaceId]);

        let oldTimeBlocks: TTimeBlockRange[] = [];

        for (const page of oldTimlineResource?.pages ?? []) {
          const res = page.find((r) => r.id === vars.resourceId);
          if (!res) continue;
          const project = res.projects.find((p) => p.id === vars.projectId);
          if (!project) continue;
          oldTimeBlocks = [...oldTimeBlocks, ...(project?.timeblocks ?? [])];
        }

        // update resource list
        const updatedResourceList = queryClient.setQueriesData<
          TTimelineResource[]
        >({ queryKey: [RESOURCES_QUERY_KEY, workspaceId] }, (prevData) => {
          try {
            if (!prevData) return prevData;
            const cpd = cloneDeep(prevData);
            const resource = find(cpd, (r) => r.id === vars.resourceId);
            if (!resource) return prevData;
            let project = resource.projects.find(
              (p) => p.id === vars.projectId,
            );

            if (!project && oldProject) {
              resource.projects.push(oldProject);
              project = oldProject;
            } else if (!project) return prevData;

            vars.events.forEach(
              ({
                operation: op,
                allocationId,
                startDate,
                endDate,
                allocation,
              }) => {
                const newAllocation =
                  allocation *
                  (differenceInWeeks(new Date(endDate), new Date(startDate)) +
                    1);
                switch (op) {
                  case ALLOCATION_EVENT_OPERATION_TYPE.INSERT:
                    project.totalAllocation += newAllocation;
                    break;
                  case ALLOCATION_EVENT_OPERATION_TYPE.UPDATE: {
                    const oldAlloc = find(
                      oldTimeBlocks,
                      ({ id }) => id === allocationId,
                    );

                    if (oldAlloc) {
                      const oldAllocValue =
                        oldAlloc.allocation *
                        (differenceInWeeks(oldAlloc.end, oldAlloc.start) + 1);
                      project.totalAllocation += newAllocation - oldAllocValue;
                    }
                    break;
                  }
                  case ALLOCATION_EVENT_OPERATION_TYPE.DELETE: {
                    const oldAlloc = find(
                      oldTimeBlocks,
                      ({ id }) => id === allocationId,
                    );
                    if (oldAlloc) {
                      const oldAllocValue =
                        oldAlloc.allocation *
                        (differenceInWeeks(oldAlloc.end, oldAlloc.start) + 1);
                      project.totalAllocation -= oldAllocValue;
                    }
                    break;
                  }
                }
              },
            );
            return cpd;
          } catch (e) {
            console.error(e);
            return prevData;
          }
        });

        const oldResource = updatedResourceList
          .reduce(
            (acc, [_query, res]) => [...acc, ...(res ?? [])],
            [] as TTimelineResource[],
          )
          ?.find((r) => r.id === vars.resourceId);
        const oldProject = oldResource?.projects.find(
          (p) => p.id === vars.projectId,
        );

        // update timeline resource data
        queryClient.setQueryData<
          InfiniteData<TTimelineResource[], TTimeInterval>
        >([TIMELINE_RESOURCE_QUERY_KEY, workspaceId], (prevData) => {
          if (!prevData) return prevData;
          const clone = cloneDeep(prevData);

          vars.events.forEach((a_evt) => {
            switch (a_evt.operation) {
              case ALLOCATION_EVENT_OPERATION_TYPE.INSERT:
                {
                  const pageIndex = prevData.pageParams.findIndex(
                    ({ start, end }) => {
                      const allocStart = new Date(a_evt.startDate);
                      return allocStart >= start && allocStart <= end;
                    },
                  );
                  if (pageIndex > -1) {
                    const page = clone.pages[pageIndex];
                    let resource = page.find((r) => r.id === vars.resourceId);
                    if (!resource && oldResource) {
                      resource = oldResource;
                      page.push(resource);
                    }
                    if (!resource) return; // non dovrebbe succedere
                    let project = resource?.projects?.find(
                      (p) => p.id === vars.projectId,
                    );
                    if (!project && oldProject) {
                      project = oldProject;
                      resource.projects = [...resource.projects, project];
                    }
                    if (!project) return;
                    project.timeblocks = [
                      ...(project.timeblocks ?? []),
                      {
                        id: a_evt.allocationId,
                        start: new Date(a_evt.startDate),
                        end: new Date(a_evt.endDate),
                        allocation: a_evt.allocation,
                      },
                    ];
                  }
                }
                break;
              case ALLOCATION_EVENT_OPERATION_TYPE.UPDATE:
                {
                  clone.pages = clone.pages?.map((resources) =>
                    resources.map((r) =>
                      r.id === vars.resourceId
                        ? {
                            ...r,
                            projects: r.projects.map((p) =>
                              p.id === vars.projectId
                                ? {
                                    ...p,
                                    timeblocks: p.timeblocks?.map((a) =>
                                      a.id === a_evt.allocationId
                                        ? {
                                            ...a,
                                            start: new Date(a_evt.startDate),
                                            end: new Date(a_evt.endDate),
                                            allocation: a_evt.allocation,
                                          }
                                        : a,
                                    ),
                                  }
                                : p,
                            ),
                          }
                        : r,
                    ),
                  );
                }
                break;
              case ALLOCATION_EVENT_OPERATION_TYPE.DELETE:
                {
                  clone.pages = clone.pages?.map((resources) => {
                    return resources.map((r) => {
                      if (r.id === vars.resourceId) {
                        return {
                          ...r,
                          projects: r.projects.map((p) => {
                            if (p.id === vars.projectId) {
                              return {
                                ...p,
                                timeblocks: p.timeblocks?.filter(
                                  (a) => a.id !== a_evt.allocationId,
                                ),
                              };
                            }
                            return p;
                          }),
                        };
                      }
                      return r;
                    });
                  });
                }
                break;
            }
          });
          return clone;
        });
        return { oldResources, oldTimelineResource };
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [INSIGHT_PART_QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [PROJECTS_LIST_WITH_RESOURCES_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TIMELINE_PROJECT_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [USAGE_INFO_QUERY_KEY, workspaceId],
      });
    },
    onError: (error: Error, _, context) => {
      context?.oldResources.forEach(([key, data]) => {
        queryClient.setQueryData(key, data);
      });
      queryClient.setQueryData(
        [TIMELINE_RESOURCE_QUERY_KEY, workspaceId],
        cloneDeep(context?.oldTimelineResource),
      );
      onError?.(error);
    },
  });
}
