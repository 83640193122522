import { useContext, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';

import { UserContext } from '@/contexts/UserContext';
import { handleApiCall } from '@/services/api';
import { searchResources } from '@/services/api/workspace/resources';
import { TSuccessErrorHook } from '@/types/generic';
import { TResourceSearchResult } from '@/types/timeline';

export const SEARCH_RESOURCES_KEY = 'search-resources';
export default function useSearchResourcesQuery({
  query,
  enabled,
  projectId,
  limit = 8,
  select = (data: TResourceSearchResult[]) => data,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<TResourceSearchResult[]> & {
  query?: string;
  projectId?: string;
  limit?: number;
  select?: (data: TResourceSearchResult[]) => TResourceSearchResult[];
}) {
  const { workspaceId } = useContext(UserContext);

  const queryKey = useMemo(() => {
    const key = [SEARCH_RESOURCES_KEY, workspaceId];
    if (query) key.push(query);
    if (projectId) key.push(projectId);
    return key;
  }, [projectId, query, workspaceId]);
  return useQuery({
    queryKey,
    queryFn: () =>
      handleApiCall(
        searchResources({ projectId, query, workspaceId, limit }),
        onSuccess,
        onError,
      ),
    enabled,
    select,
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
}
