import {
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import type { TYPE } from '@components/Badge';
import Badge from '@components/Badge';
import Button from '@components/Button';
import ModalDeleteProject from '@components/Modals/ModalDeleteProject';
import ModalEditProject from '@components/Modals/ModalEditProject';
import ModalUnassign from '@components/Modals/ModalUnassign';
import ProjectNameWithEmoji from '@components/ProjectNameWithEmoji';
import type { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  IconArchive,
  IconDots,
  IconEraser,
  IconGripVertical,
  IconLink,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { t } from 'i18next';
import { cloneDeep, find } from 'lodash';
import { RightSlot, Separator, contextMenu } from 'react-contexify';

import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import { useAnalytics } from '@/hooks/utils/useAnalytics';
import useHideProject from '@/hooks/workspace/projects/useHideProject';
import useUnassignProjectToResource from '@/hooks/workspace/projects/useUnassignProjectToResource';
import { RESOURCES_QUERY_KEY } from '@/hooks/workspace/resources/useResourcesQuery';
import { useUpdateProjectStatus } from '@/hooks/workspace/resources/useUpdateStatus';
import { getCssVariable, shrinkValue, truncate } from '@/services/helpers';
import { MENU_WIDTH } from '@/types/constants';
import {
  PROJECT_STATUS,
  PROJECT_TYPE,
  WORKSPACE_MEMBER_PERMISSION,
} from '@/types/enums';
import {
  TResources,
  TSharedProjectInfo,
  TTimelineProject,
} from '@/types/timeline';
import { TUserWorkspace } from '@/types/workspace';

import { StatusIndicator } from '@/components/Indicators';
import ModalShareProject from '@/components/Modals/ModalShareProject';
import ShortcutMenu from '@/components/ShortcutMenu';

import styles from './styles.module.css';
import ChangeProjectStatusItem from '../../common/ChangeProjectStatusItem';
import RightSlotDelete from '../../common/utils/RightSlotDelete';

type Props = PropsWithChildren<{
  project: TTimelineProject;
  allocation: number;
  label?: string;
  labelType?: TYPE;
  resourceId: string;
  resourceName: string;
  sortHandlerProps?: {
    listeners?: SyntheticListenerMap;
    activator: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
  };
}>;

export default function RowLeftHeader({
  project,
  children,
  resourceId,
  resourceName,
  allocation,
  label,
  labelType,
  sortHandlerProps,
}: Props) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number } | undefined>(
    undefined,
  );
  const { layoutIsExpanded } = useContext(UIContext);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { account, workspaceId } = useContext(UserContext);
  const workspace = find(account?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;
  const [isModalDeleteProjectOpen, setIsModalDeleteProjectOpen] =
    useState(false);
  const [isModalEditProjectOpen, setIsModalEditProjectOpen] = useState(false);
  const onOpenModalDeleteProject = useCallback(
    () => setIsModalDeleteProjectOpen(true),
    [],
  );

  const onOpenModalEditProject = useCallback(
    () => setIsModalEditProjectOpen(true),
    [],
  );

  const onCloseModalDeleteProject = useCallback(
    () => setIsModalDeleteProjectOpen(false),
    [],
  );

  const onCloseModalEditProject = useCallback(
    () => setIsModalEditProjectOpen(false),
    [],
  );

  const [isModalUnassignProjectOpen, setIsModalUnassignProjectOpen] =
    useState(false);

  const [isModalShareProjectOpen, setIsModalShareProjectOpen] =
    useState<boolean>(false);

  const onOpenModalUnassignProject = useCallback(
    () => setIsModalUnassignProjectOpen(true),
    [],
  );
  const onCloseModalUnassignProject = useCallback(
    () => setIsModalUnassignProjectOpen(false),
    [],
  );

  function getMenuPosition() {
    const { left, bottom, width } =
      triggerRef?.current?.getBoundingClientRect() ?? {
        left: 0,
        bottom: 0,
        width: 0,
      };
    menuPositionRef.current = { x: left - (MENU_WIDTH - width), y: bottom + 8 };
    return menuPositionRef.current;
  }

  const { mutate: mutateHideProject } = useHideProject();
  const onHideProjectFn = useCallback(() => {
    mutateHideProject({ projectId: project.id, resourceId });
  }, [mutateHideProject, project.id, resourceId]);

  const MENU_ID = `team-context-menu-${project.id}-${resourceId}`;
  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (menuIsOpen) {
        contextMenu.hideAll();
        return;
      }
      contextMenu.show({
        id: MENU_ID,
        event: e,
        position: getMenuPosition(),
      });
    },
    [MENU_ID, menuIsOpen],
  );

  const { mutate, isPending } = useUnassignProjectToResource();

  const onUnassignProjectFn = useCallback(() => {
    mutate(
      { resourceId, projectId: project?.id ?? '' },
      { onSuccess: onCloseModalUnassignProject },
    );
  }, [mutate, onCloseModalUnassignProject, project?.id, resourceId]);

  const [submenuOpened, setSubmenuOpened] = useState(false);

  const openSubMenu = useCallback(() => {
    setSubmenuOpened(true);
  }, []);

  const closeSubMenu = useCallback((clicked?: boolean) => {
    setSubmenuOpened(false);
    if (clicked) {
      contextMenu.hideAll();
    }
  }, []);

  const { trackEvent } = useAnalytics();

  const { mutate: updateProjectStatus } = useUpdateProjectStatus();

  const onItemClickFn = useCallback(
    (newStatus: PROJECT_STATUS) => {
      updateProjectStatus(
        { resourceId, projectId: project?.id, status: newStatus },
        {
          onSuccess: () =>
            trackEvent('PROJECT_STATUS_CHANGED', workspaceId as string),
        },
      );
      closeSubMenu(true);
    },
    [
      closeSubMenu,
      project.id,
      resourceId,
      trackEvent,
      updateProjectStatus,
      workspaceId,
    ],
  );

  const type = project.type;
  const queryClient = useQueryClient();

  const onShareSuccess = useCallback(
    async (data: TSharedProjectInfo | null, projectId: string) => {
      const key = [RESOURCES_QUERY_KEY, workspaceId];
      const queryData = cloneDeep(queryClient.getQueryData<TResources[]>(key));
      if (queryData) {
        queryData.forEach((resources) => {
          resources.projects
            .filter((project) => project.id === projectId)
            .forEach((project) => {
              project.shared = data ?? undefined;
            });
        });
        queryClient.setQueryData(key, queryData);
      }
    },
    [queryClient, workspaceId],
  );

  const onRevokeShareSuccess = useCallback(
    async (projectId: string) => {
      const key = [RESOURCES_QUERY_KEY, workspaceId];
      const queryData = cloneDeep(queryClient.getQueryData<TResources[]>(key));
      if (queryData) {
        queryData.forEach((resources) => {
          resources.projects
            .filter(
              (project) => project.id === projectId && !!project.shared?.id,
            )
            .forEach((project) => {
              project.shared = undefined;
            });
        });
        queryClient.setQueryData(key, queryData);
      }
    },
    [queryClient, workspaceId],
  );

  return (
    <div
      className={classNames([styles.container, styles[`${type}`]], {
        [styles.isExpanded]: layoutIsExpanded,
      })}
    >
      <span>
        <Badge type={type === PROJECT_TYPE.TIME_OFF ? 'timeoff' : 'white'}>
          {truncate(children as string, 12)}
        </Badge>
      </span>
      <div
        className={classNames(styles.desktop, {
          [styles.leftPadding]: type === PROJECT_TYPE.TIME_OFF,
        })}
      >
        {sortHandlerProps?.activator && type !== PROJECT_TYPE.TIME_OFF && (
          <div
            className={classNames(styles.sortHandler, {
              [styles.sortHandlerHidden]:
                workspace?.permission === WORKSPACE_MEMBER_PERMISSION.READ_ONLY,
            })}
            {...sortHandlerProps?.listeners}
            {...sortHandlerProps?.attributes}
            ref={sortHandlerProps?.activator}
          >
            <IconGripVertical
              size={16}
              color={getCssVariable('--color-neutral-500')}
            />
          </div>
        )}

        <ProjectNameWithEmoji
          name={children as string}
          type={type}
          emoji={project.emoji}
          color={project.color}
          isShared={!!project?.shared?.id}
        />
        {label && (
          <span>
            <Badge type={labelType}>{label}</Badge>
          </span>
        )}
        <p
          className={classNames(styles.allocation, {
            [styles.allocationVisible]:
              workspace?.permission !==
              WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
          })}
        >
          {shrinkValue(allocation)}
        </p>
        {type !== PROJECT_TYPE.TIME_OFF &&
          workspace?.permission ===
            WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE && (
            <div className={styles.projectMenuAction}>
              {project.status ? (
                <StatusIndicator type={project.status} size={20} />
              ) : undefined}
              <Button
                ref={triggerRef}
                icon={IconDots}
                variant="ghost"
                size="small"
                onClick={onClickFn}
              />
            </div>
          )}
      </div>
      <ShortcutMenu
        onVisibilityChange={(isVisible: boolean) => {
          setMenuIsOpen(isVisible);
          setSubmenuOpened(false);
        }}
        animation={false}
        style={{ minWidth: MENU_WIDTH }}
        id={MENU_ID}
      >
        <ShortcutMenu.Item keyHandler={onOpenModalEditProject} keyMatch="e">
          <IconPencil size={20} style={{ marginRight: 8 }} />{' '}
          {t('common:editContextMenuActions.edit')} <RightSlot>E</RightSlot>{' '}
        </ShortcutMenu.Item>
        <ChangeProjectStatusItem
          project={project}
          menuId={MENU_ID}
          keyMatch={'s'}
          keyHandler={() => setSubmenuOpened((prev) => !prev)}
          onMouseEnter={openSubMenu}
          onMouseLeave={() => closeSubMenu()}
          onFocus={openSubMenu}
          submenuOpened={submenuOpened}
          menuWidth={MENU_WIDTH}
          onItemClick={onItemClickFn}
          view="people"
        />
        <ShortcutMenu.Item
          keyHandler={() => setIsModalShareProjectOpen(true)}
          keyMatch="l"
        >
          <IconLink size={20} style={{ marginRight: 8 }} />{' '}
          {t('common:editContextMenuActions.share')} <RightSlot>L</RightSlot>{' '}
        </ShortcutMenu.Item>
        <Separator />
        <ShortcutMenu.Item keyHandler={onOpenModalUnassignProject} keyMatch="u">
          <IconEraser size={20} style={{ marginRight: 8 }} />{' '}
          {t('common:editContextMenuActions.unassign')} <RightSlot>U</RightSlot>
        </ShortcutMenu.Item>

        <ShortcutMenu.Item keyHandler={onHideProjectFn} keyMatch="h">
          <IconArchive size={20} style={{ marginRight: 8 }} />{' '}
          {t('common:editContextMenuActions.hide')} <RightSlot>H</RightSlot>
        </ShortcutMenu.Item>
        <ShortcutMenu.Item
          keyHandler={onOpenModalDeleteProject}
          keyMatch="shift+backspace"
        >
          <IconTrash size={20} style={{ marginRight: 8 }} />{' '}
          {t('common:editContextMenuActions.delete')} <RightSlotDelete />
        </ShortcutMenu.Item>
      </ShortcutMenu>
      {isModalEditProjectOpen && (
        <ModalEditProject
          key={`edit-${resourceId}_${project.id}`}
          name={children as string}
          projectId={project.id}
          color={project.color}
          projectEmoji={project.emoji}
          projectStatus={project.status}
          isOpen={isModalEditProjectOpen}
          onClose={onCloseModalEditProject}
        />
      )}
      {isModalDeleteProjectOpen && (
        <ModalDeleteProject
          key={`delete-${resourceId}_${project.id}`}
          name={children as string}
          projectId={project.id}
          isOpen={isModalDeleteProjectOpen}
          onClose={onCloseModalDeleteProject}
        />
      )}
      {isModalUnassignProjectOpen && (
        <ModalUnassign
          modalKey={`remove-${resourceId}_${project.id}`}
          isLoading={isPending}
          modalTitle={t('common:modals.removeProject.title')}
          title={t('common:modals.removeProject.subtitle', { value: children })}
          description={t('common:modals.removeProject.description', {
            value: resourceName,
          })}
          onSave={onUnassignProjectFn}
          isOpen={isModalUnassignProjectOpen}
          onClose={onCloseModalUnassignProject}
        />
      )}
      {isModalShareProjectOpen && (
        <ModalShareProject
          isOpen={isModalShareProjectOpen}
          onClose={() => setIsModalShareProjectOpen(false)}
          projectId={project.id}
          shared={project.shared}
          onShare={onShareSuccess}
          onRevokeShare={onRevokeShareSuccess}
        />
      )}
    </div>
  );
}
