import { useQuery } from '@tanstack/react-query';
import { noop } from 'lodash';

import { handleApiCall } from '@/services/api';
import { getImageFromFirebase } from '@/services/api/firebase';
import { TSuccessErrorHook } from '@/types/generic';

export const KEY = 'get-firebase-image';
export default function useImageFirebaseQuery({
  path = '',
  enabled = true,
  onSuccess = noop,
  onError = noop,
}: TSuccessErrorHook<string> & {
  path?: string;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: [KEY, path],
    queryFn: ({ queryKey: [_, pth] }) =>
      handleApiCall(getImageFromFirebase(pth), onSuccess, onError),
    retry: 10,
    enabled,
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
