import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type { User as FirebaseUser } from '@firebase/auth';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import { find, noop } from 'lodash';

import useAccountQuery from '@/hooks/account/useAccountQuery';
import useImageFirebaseQuery from '@/hooks/firebase/useImageFirebaseQuery';
import useGetSubscription from '@/hooks/workspace/subscriptions/useGetSubscription';
import useUsageInfo from '@/hooks/workspace/useUsageInfo';
import { isSubscriptionActive } from '@/services/helpers';
import { SubscriptionStatusEnum, WORKSPACE_MODE } from '@/types/enums';
import {
  TSubscriptionDto,
  TUserAccount,
  TUserWorkspace,
  TWorkspaceUsageInfo,
} from '@/types/workspace';

import { useAnalytics } from '../hooks/utils/useAnalytics';

const UserContext = React.createContext<{
  isLoggedIn: boolean | null;
  user: FirebaseUser | null;
  isLoading: boolean;
  isUserEmailVerified: boolean | null;
  setIsUserEmailVerified: (value: boolean) => void;
  account: TUserAccount | null;
  workspaceId?: string | null;
  clearLocalStorage: (logout?: boolean) => void;
  workspaceMode?: WORKSPACE_MODE;
  mobileExperienceAccepted: boolean | null;
  setMobileExperienceAccepted: (value: boolean) => void;
  subscription?: TSubscriptionDto | null;
  isSubscriptionLoading: boolean;
  setIsSubscriptionLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  workspace?: TUserWorkspace;
  usageInfoData?: {
    overflow: boolean;
    perc: number;
    maxProjects: number;
    value: number;
    data?: TWorkspaceUsageInfo;
  };
  modeMultiplier: number;
  setWorkspaceId: (wsId: string | undefined) => void;
  isMacOs: boolean;
  disablePayment?: boolean;
}>({
  isLoggedIn: false,
  user: null,
  isLoading: false,
  clearLocalStorage: noop,
  isUserEmailVerified: false,
  setIsUserEmailVerified: noop,
  setWorkspaceId: noop,
  setMobileExperienceAccepted: noop,
  workspaceId: '',
  workspace: undefined,
  workspaceMode: WORKSPACE_MODE.DAYS,
  mobileExperienceAccepted: false,
  subscription: undefined,
  isSubscriptionLoading: false,
  setIsSubscriptionLoading: undefined,
  account: null,
  modeMultiplier: 8,
  usageInfoData: undefined,
  isMacOs: false,
  disablePayment: false,
});

const UserProvider = ({ children }: PropsWithChildren) => {
  const [isLoggedIn = false, setIsLoggedIn] = useLocalStorage<
    boolean | undefined
  >('user-logged', false);
  const [workspaceMode, setWorkspaceMode] = useState<WORKSPACE_MODE>(
    WORKSPACE_MODE.DAYS,
  );
  const [workspaceId, setWorkspaceId] = useLocalStorage<string | undefined>(
    `pln-ws`,
    undefined,
  );
  const isMacOs = useMemo(() => {
    const usAgent = navigator.userAgent.toUpperCase();
    return usAgent.indexOf('MAC') > -1 || usAgent.indexOf('MACOS') > -1;
  }, []);

  const [mobileExperienceAccepted, setMobileExperienceAccepted] =
    useLocalStorage<boolean>(`mobileExperience`, false);

  const auth = getAuth();
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [isUserEmailVerified, setIsUserEmailVerified] = useState<
    boolean | null
  >(false);

  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(true);
  const enabled = useMemo(
    () => Boolean(isUserEmailVerified),
    [isUserEmailVerified],
  );
  const { data: userAccount, isLoading: isLoadingUserAccount } =
    useAccountQuery({
      enabled,
    });

  // const { data: countryCode } = useGetCountryCode();

  const _isLoadingUserAccount =
    Boolean(auth.currentUser?.email) && isLoadingUserAccount;

  const { trackUserId, trackGroupId } = useAnalytics();
  const workspace = find(userAccount?.workspaces, {
    id: workspaceId,
  }) as TUserWorkspace;

  const { data: userAvatar } = useImageFirebaseQuery({
    path: userAccount?.image?.ref,
    enabled: Boolean(userAccount?.image?.ref),
  });
  const clearLocalStorage = useCallback(
    (logout: boolean = true) => {
      setWorkspaceId(undefined);
      if (logout) setIsLoggedIn(undefined);
    },
    [setIsLoggedIn, setWorkspaceId],
  );

  const [userAlreadyTracked, setUserAlreadyTracked] = useState<boolean>(false);
  // Track user and group ids
  useEffect(() => {
    if (!isLoggedIn || !user || !auth || userAlreadyTracked || !workspace)
      return;

    setUserAlreadyTracked(true);
    trackUserId({
      email: user?.email as string,
      name:
        userAccount?.firstName && userAccount.lastName
          ? `${userAccount?.firstName} ${userAccount?.lastName}`
          : '',
      avatar: userAvatar ?? undefined,
      weeklyEmail: workspace?.weeklyEmail,
      workspaceRole: workspace?.role,
      workspacePermission: workspace?.permission,
    });
    trackGroupId({
      id: workspace?.id,
      groupName: workspace?.name,
      timeMode: workspace?.mode,
    });
  }, [
    trackGroupId,
    userAlreadyTracked,
    setUserAlreadyTracked,
    workspace,
    trackUserId,
    user,
    isLoggedIn,
    auth,
    userAccount,
    userAvatar,
  ]);

  const { data: usageInfo } = useUsageInfo({
    workspaceId: workspace?.id ?? '',
    enabled: !!workspace?.id && Boolean(isLoggedIn),
  });

  const { data: subscription, isLoading: subIsLoading } = useGetSubscription({
    workspaceId: workspace?.id ?? '',
    enabled: !!workspace?.id && Boolean(isLoggedIn),
  });

  const [isSubscriptionLoading, setIsSubscriptionLoading] =
    useState<boolean>(subIsLoading);

  const [value, perc, maxProjects]: [number, number, number] = useMemo(() => {
    const val = usageInfo?.activeProjects ?? 0;
    // const max = subscription?.status ? subscription.maxProjects : 5;
    let max: number = 5;
    if (
      subscription?.status &&
      [SubscriptionStatusEnum.active, SubscriptionStatusEnum.trialing].includes(
        subscription.status,
      )
    )
      max = subscription.maxProjects;
    return [val, Math.min(Number(((val * 100) / max).toFixed(2)), 100), max];
  }, [usageInfo?.activeProjects, subscription]);

  const overflow = useMemo(() => value >= maxProjects, [value, maxProjects]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setIsLoggedIn(true);
        setIsUserEmailVerified(user.emailVerified);
      } else {
        setIsLoggedIn(false);
        setUser(null);
        setIsUserEmailVerified(null);
      }
      setIsFetchingUser(false);
    });
    return unsubscribe;
  }, [auth, setIsFetchingUser, setIsLoggedIn]);

  useEffect(() => {
    if (workspace?.mode) {
      setWorkspaceMode(workspace.mode);
    } else setWorkspaceMode(WORKSPACE_MODE.DAYS);
  }, [workspace?.mode]);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        user,
        setMobileExperienceAccepted,
        mobileExperienceAccepted,
        clearLocalStorage,
        isUserEmailVerified,
        setIsUserEmailVerified,
        account: userAccount ?? null,
        subscription,
        isSubscriptionLoading,
        setIsSubscriptionLoading,
        workspaceMode,
        isLoading:
          (isFetchingUser && !user) || (enabled && _isLoadingUserAccount),
        workspace,
        workspaceId,
        setWorkspaceId,
        usageInfoData: {
          overflow,
          perc,
          maxProjects,
          value,
          data: usageInfo ?? undefined,
        },
        modeMultiplier: workspaceMode === WORKSPACE_MODE.DAYS ? 8 : 1,
        isMacOs,
        disablePayment: import.meta.env.VITE_DISABLE_PAYMENT === 'true',
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useSubscription = () => {
  const ctx = useContext(UserContext);
  if (!ctx)
    throw new Error('useSubscription must be used within a UserProvider');
  const { workspace } = ctx;

  const plan = useMemo(() => {
    if (!ctx.subscription) return 'free';
    else return 'pro';
  }, [ctx?.subscription]);

  const renewal = useMemo(() => {
    if (ctx.subscription) {
      const { status } = ctx.subscription;
      return status === 'trialing'
        ? ctx.subscription.billingPeriodEnd
        : ctx.subscription.nextBillingDate;
    }
  }, [ctx?.subscription]);

  const isPastDue = useMemo(() => {
    return ctx.subscription?.status === SubscriptionStatusEnum.past_due;
  }, [ctx.subscription?.status]);

  return {
    subscription: ctx?.subscription,
    isSubscriptionLoading: ctx.isSubscriptionLoading,
    setIsSubscriptionLoading: ctx.setIsSubscriptionLoading,
    plan,
    renewal,
    skipTrailing: workspace?.skipTrailing ?? false,
    isPastDue,
    isActive: isSubscriptionActive(ctx?.subscription),
    isPending:
      !!ctx?.subscription &&
      ctx.subscription.status === SubscriptionStatusEnum.pending,
  };
};

export { UserContext, UserProvider, useSubscription };
