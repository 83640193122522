import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { TimelineProjectsContext } from '@components/Timelines/TimelineProjects/context';
import { getAuth } from '@firebase/auth';
import {
  IconChartPie2,
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconLogout,
  IconSettings,
} from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { differenceInDays } from 'date-fns';
import { t } from 'i18next';
import { Item, RightSlot, Separator, contextMenu } from 'react-contexify';
import { isMobile } from 'react-device-detect';
import { type Options, useHotkeys } from 'react-hotkeys-hook';
import { useLocation, useNavigate } from 'react-router';

import { useProfileModal } from '@/contexts/ModalPreferenceContext';
import { UIContext } from '@/contexts/UIContext';
import { UserContext } from '@/contexts/UserContext';
import useGetSubscription from '@/hooks/workspace/subscriptions/useGetSubscription';
import { GLOBAL_SCOPE } from '@/types/constants';
import {
  SubscriptionStatusEnum,
  WORKSPACE_MEMBER_PERMISSION,
} from '@/types/enums';
import { ROUTES } from '@/types/routes';

import Button from '@/components/Button';
import ShortcutMenu from '@/components/ShortcutMenu';
import { TimelineResourcesContext } from '@/components/Timelines/TimelineResources/context';

import Avatar from '../Avatar';
import Chip from '../Chip';
import LogoHeader from '../LogoHeader';
import ModalUpgradePlan from '../Modals/ModalUpgradePlan';
import { ContentPages } from '../Modals/ModalUserProfile';
import Portal from '../Portal';
import AddResourceProjectButton from './AddResourceProjectButton';
import styles from './styles.module.css';

export default function Header() {
  const auth = getAuth();
  const {
    setResetViewOnToday: setResetViewOnTodayProjectViewFn,
    onNextFn: onNextProjectViewFn,
    onPrevFn: onPrevProjectViewFn,
  } = useContext(TimelineProjectsContext);
  const {
    setResetViewOnToday: setResetViewOnTodayResourceViewFn,
    onNextFn: onNextResourceViewFn,
    onPrevFn: onPrevResourceViewFn,
  } = useContext(TimelineResourcesContext);
  const { account, workspaceId, clearLocalStorage, workspace } =
    useContext(UserContext);
  const { data: subscription, isLoading } = useGetSubscription({
    workspaceId: workspace?.id ?? '',
    enabled: !!workspace?.id,
  });
  const menuPositionRef = useRef<{ x: number; y: number }>(undefined);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const { drawerIsOpen, setDrawerOpen } = useContext(UIContext);

  const { pathname } = useLocation();

  const isProjectsPage = useMemo(
    () => pathname.includes('projects'),
    [pathname],
  );
  const onNextLocalFn = useCallback(() => {
    if (isProjectsPage) {
      onNextProjectViewFn();
    } else {
      onNextResourceViewFn();
    }
  }, [onNextProjectViewFn, isProjectsPage, onNextResourceViewFn]);

  const onPrevLocalFn = useCallback(() => {
    if (isProjectsPage) {
      onPrevProjectViewFn();
    } else {
      onPrevResourceViewFn();
    }
  }, [onPrevProjectViewFn, isProjectsPage, onPrevResourceViewFn]);

  const onSetResetViewOnTodayFn = useCallback(() => {
    if (isProjectsPage) {
      setResetViewOnTodayProjectViewFn(true);
    } else {
      setResetViewOnTodayResourceViewFn(true);
    }
  }, [
    isProjectsPage,
    setResetViewOnTodayProjectViewFn,
    setResetViewOnTodayResourceViewFn,
  ]);

  const { openModal } = useProfileModal();
  function getMenuPosition() {
    const { left, bottom, width } =
      triggerRef?.current?.getBoundingClientRect() ?? {
        left: 0,
        bottom: 0,
        width: 0,
      };
    menuPositionRef.current = { x: left - (180 - width), y: bottom + 8 };
    return menuPositionRef.current;
  }
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen, workspaceId]);
  const onLogoutFn = async () => {
    await auth?.signOut();
    queryClient.clear();
    clearLocalStorage();
    navigate(ROUTES.SIGN_IN, {
      preventScrollReset: false,
      replace: true,
    });
  };

  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (menuIsOpen) {
        contextMenu.hideAll();
        return;
      }

      contextMenu.show({
        id: 'preference-menu',
        event: e,
        position: getMenuPosition(),
      });
    },
    [menuIsOpen],
  );
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const chipElement = useMemo(() => {
    if (isLoading) return null;
    switch (subscription?.status) {
      case null:
      case undefined:
        return (
          <>
            <Chip
              size="medium"
              type="neutral"
              onClick={() => setModalOpen(true)}
              asButton
            >
              Free
            </Chip>
            {modalOpen && (
              <ModalUpgradePlan
                modalOpened={modalOpen}
                onClose={() => setModalOpen(false)}
                changePlanTo="pro"
              />
            )}
          </>
        );
      case SubscriptionStatusEnum.pending:
        return (
          <Chip
            size="medium"
            type="neutral"
            asButton
            onClick={() => openModal(ContentPages.PLANS)}
          >
            Pending
          </Chip>
        );
        break;
      case SubscriptionStatusEnum.trialing:
        if (subscription.billingPeriodEnd) {
          const daysLeft = Math.max(
            differenceInDays(subscription.billingPeriodEnd, new Date()),
            0,
          );
          const daysLeftStr: string = `${daysLeft} days left`;
          return (
            <Chip
              size="medium"
              type="neutral"
              onClick={() => openModal(ContentPages.PLANS)}
              asButton
            >
              {daysLeftStr}
            </Chip>
          );
        }
        break;
      case SubscriptionStatusEnum.past_due:
        return (
          <Chip
            size="medium"
            type="caution"
            onClick={() => openModal(ContentPages.BILLING_INFO)}
            asButton
          >
            Past due
          </Chip>
        );
      default:
        return (
          <Chip
            size="medium"
            type="neutral"
            onClick={() => openModal(ContentPages.PLANS)}
            asButton
          >
            Pro
          </Chip>
        );
    }
  }, [
    isLoading,
    modalOpen,
    openModal,
    subscription?.billingPeriodEnd,
    subscription?.status,
  ]);

  const keyOptions: Options = useMemo(
    () =>
      ({
        preventDefault: true,
        scopes: GLOBAL_SCOPE,
      }) as Options,
    [],
  );
  const openProfileModal = useCallback(() => {
    contextMenu.hideAll();

    openModal(ContentPages.PROFILE_INFO);
  }, [openModal]);
  useHotkeys('p', openProfileModal, keyOptions, [openProfileModal]);
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <LogoHeader />
        {chipElement}
      </div>
      <div className={classNames([styles.elements, styles.elementsAbsolute])}>
        <Button
          icon={IconChevronLeft}
          variant="ghost"
          onClick={onPrevLocalFn}
        />
        <Button
          label="Today"
          onClick={onSetResetViewOnTodayFn}
          variant="ghost"
        />
        <Button
          icon={IconChevronRight}
          variant="ghost"
          onClick={onNextLocalFn}
        />
      </div>
      <div className={styles.elements}>
        {isMobile ? (
          <Button
            icon={IconChartPie2}
            onClick={() => setDrawerOpen(!drawerIsOpen)}
            size="medium"
            variant="ghost"
            active={drawerIsOpen}
          />
        ) : (
          <Button
            label={t('common:insights.title')}
            onClick={() => setDrawerOpen(!drawerIsOpen)}
            size="medium"
            variant="outline"
            active={drawerIsOpen}
          />
        )}

        {workspace?.permission === WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE &&
          !isMobile && <AddResourceProjectButton />}

        <button ref={triggerRef} onClick={onClickFn}>
          <Avatar
            size="large"
            image={account?.image?.ref ?? ''}
            name={
              (`${account?.firstName ?? ''} ${
                account?.lastName ?? ''
              }`.trim() ||
                auth.currentUser?.email) ??
              ''
            }
          />
        </button>
        <Portal>
          <ShortcutMenu
            onVisibilityChange={setMenuIsOpen}
            animation={false}
            className="shrinkSeparator is-right"
            style={{ width: 180, top: 80 }}
            id="preference-menu"
          >
            {account?.workspaces?.length && (
              <Item disabled className={styles.workspaceLabel}>
                {t('common:workspaces')}
              </Item>
            )}
            {account?.workspaces?.map((workspace, idx) =>
              account?.workspaces.length > 1 ? (
                <ShortcutMenu.Item
                  key={workspace.id}
                  id={workspace.id}
                  keyMatch={`${idx + 1}`}
                  keyHandler={() => {
                    if (workspaceId && workspaceId === workspace.id) return;
                    navigate(
                      `${ROUTES.DASHBOARD?.replace(
                        ':workspaceId',
                        workspace.id,
                      )}`,
                    );
                  }}
                  className={styles.workspaceItem}
                >
                  {workspaceId && workspaceId === workspace.id ? (
                    <IconCheck size={20} />
                  ) : (
                    <span className={styles.workspaceItemSpacer} />
                  )}
                  <span className={styles.workspaceItemLabel}>
                    {workspace.name}
                  </span>
                  <RightSlot>{idx + 1}</RightSlot>
                </ShortcutMenu.Item>
              ) : (
                <Item
                  key={workspace.id}
                  id={workspace.id}
                  onClick={() => {
                    if (workspaceId && workspaceId === workspace.id) return;
                    navigate(
                      `${ROUTES.DASHBOARD?.replace(
                        ':workspaceId',
                        workspace.id,
                      )}`,
                    );
                  }}
                  className={styles.workspaceItem}
                >
                  {workspaceId && workspaceId === workspace.id ? (
                    <IconCheck size={20} />
                  ) : (
                    <span className={styles.workspaceItemSpacer} />
                  )}
                  <span className={styles.workspaceItemLabel}>
                    {workspace.name}
                  </span>
                </Item>
              ),
            )}
            <Separator hidden={!account?.workspaces?.length} />
            <ShortcutMenu.Item
              keyMatch={'P'}
              keyHandler={() => {
                openModal(ContentPages.PROFILE_INFO);
              }}
            >
              <IconSettings size={20} style={{ marginRight: 8 }} />
              Preferences
              <RightSlot>P</RightSlot>
            </ShortcutMenu.Item>
            <Item onClick={onLogoutFn}>
              <IconLogout size={20} style={{ marginRight: 8 }} />{' '}
              {t('common:logout')}
            </Item>
          </ShortcutMenu>
        </Portal>
      </div>
    </div>
  );
}
