import { MouseEventHandler, useCallback } from 'react';

import classNames from 'classnames';

import styles from './styles.module.css';

type BaseProps = {
  children: React.ReactNode;
  size?: 'small' | 'medium';
  type?:
    | 'alert'
    | 'informative'
    | 'success'
    | 'critical'
    | 'neutral'
    | 'caution'
    | 'dashed';
  asButton?: boolean;
  className?: string;
};
type PropsDiv = BaseProps & {
  asButton?: false;
  onClick?: never;
};

type PropsButton = BaseProps & {
  asButton: true;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

type Props = PropsDiv | PropsButton;

export default function Chip(props: Props) {
  const { children, size = 'medium', type = 'neutral' } = props;

  const renderElement = useCallback(() => {
    if (props.asButton) {
      return (
        <button
          className={classNames([
            styles.container,
            styles[type],
            styles[size],
            props.className ?? '',
          ])}
          onClick={props.onClick}
        >
          {children}
        </button>
      );
    } else {
      return (
        <div
          className={classNames([
            styles.container,
            styles[type],
            styles[size],
            props.className ?? '',
          ])}
        >
          {children}
        </div>
      );
    }
  }, [children, props.asButton, props.className, props.onClick, size, type]);

  return renderElement();
}
