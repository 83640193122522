import { useContext, useMemo } from 'react';

import { type InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { handleApiCall } from '@/services/api';
import { searchManagerResources } from '@/services/api/workspace/resources';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TResourceSearchResult } from '@/types/timeline';

type ResourceManagerSelectType = InfiniteData<{
  results: TResourceSearchResult[];
  count: number;
  totalCount: number;
  page: number;
  pageSize: number;
  nextPage: number | null;
  previousPage: number | null;
}>;

const PAGE_SIZE = 15;
export const SEARCH_MANAGER_RESOURCES_KEY = 'search-manager-resources';
export default function useSearchManagerResourcesQuery({
  query,
  enabled,
  projectId,
  showhidden = false,
  onError = noop,
}: TSuccessErrorHook<TResourceSearchResult[]> & {
  query?: string;
  projectId?: string;
  showhidden?: boolean;
  select?: (data: ResourceManagerSelectType) => TResourceSearchResult[];
}) {
  const { workspaceId } = useContext(UserContext);
  const { addNotification } = useContext(NotificationsContext);

  const queryKey = useMemo(() => {
    const key = [SEARCH_MANAGER_RESOURCES_KEY, workspaceId, showhidden];
    if (query) key.push(query);
    if (projectId) key.push(projectId);
    return key;
  }, [projectId, query, workspaceId, showhidden]);
  return useInfiniteQuery({
    queryKey,
    initialPageParam: 1,
    queryFn: ({ pageParam = 1, queryKey: [_key, _workspaceId, showHidden] }) =>
      handleApiCall(
        searchManagerResources({
          projectId,
          query,
          workspaceId,
          showHidden: showHidden as boolean,
          pageSize: PAGE_SIZE,
          page: pageParam,
        }),
        undefined,
        (error: TResponseError) => {
          onError(error);
          addNotification({
            type: 'error',
            title: t('errors:generic.title') as string,
            description:
              error?.message ?? (t('errors:generic.description') as string),
          });
        },
      ),
    enabled,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    getPreviousPageParam: (lastPage) => lastPage.previousPage ?? undefined,
    retry: 3,
    refetchOnMount: true,
  });
}
