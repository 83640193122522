import { useContext } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { ACCOUNT_QUERY_KEY } from '@/hooks/account/useAccountQuery';
import { http } from '@/services/api';
import { BaseResponse } from '@/types/base-responses';
import { TUserAccount } from '@/types/workspace';



export type CustomerUpdateType = {
  customerId: string;
  addressId?: string;
  businessId?: string;
};

export default function useUpdateCustomer() {
  const { workspaceId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { addNotification } = useContext(NotificationsContext);
  return useMutation({
    mutationFn: async (updater: CustomerUpdateType) => {
      const {
        data: { data },
      } = await http.put<BaseResponse<CustomerUpdateType>>(
        `/workspaces/${workspaceId}/customer/${updater.customerId}`,
        {
          addressId: updater.addressId,
          businessId: updater.businessId,
        },
      );
      return data;
    },
    onMutate: async (body: CustomerUpdateType) => {
      const accountData = queryClient.getQueryData<TUserAccount>([
        ACCOUNT_QUERY_KEY,
      ]);
      const dt = cloneDeep(accountData);
      const oldCustomer = dt?.workspaces.find(
        (w) => w.id === workspaceId,
      )?.customer;
      if (oldCustomer) {
        oldCustomer.addressId = body.addressId;
        oldCustomer.businessId = body.businessId;
        queryClient.setQueryData<TUserAccount>([ACCOUNT_QUERY_KEY], dt);
      }
      return { accountData };
    },
    onError: (error: Error, variables, context) => {
      queryClient.setQueryData<TUserAccount>(
        [ACCOUNT_QUERY_KEY],
        context?.accountData,
      );
      addNotification({
        type: 'error',
        title: t('errors:generic.title') as string,
        description:
          error?.message ?? (t('errors:generic.description') as string),
      });
    },
  });
}
