import { t } from 'i18next';

import { ROUTES } from '@/types/routes';

import Button from '@/components/Button';

import { ErrorScreen } from '..';
import styles from './styles.module.css';

export default function Error404() {
  return (
    <ErrorScreen>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={styles.head}>{t('common:notFoundPage.title')}</h1>
          <p>{t('common:notFoundPage.description')}</p>
        </div>
        <div className={styles.cta}>
          <Button
            label={t('common:notFoundPage.ctaLabel')}
            size="large"
            link={{
              to: ROUTES.ROOT,
              preventScrollReset: false,
            }}
          />
        </div>
      </div>
    </ErrorScreen>
  );
}
