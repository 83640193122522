import { useContext } from 'react';

import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import {
  add as addWeeks,
  endOfDay,
  startOfDay,
  sub as subWeeks,
} from 'date-fns';
import { t } from 'i18next';
import { isUndefined, noop } from 'lodash';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { useSortAndFilter } from '@/contexts/SortAndFilterContext';
import { UserContext } from '@/contexts/UserContext';
import { handleApiCall } from '@/services/api';
import { getTimelineResources } from '@/services/api/workspace/resources';
import { TResponseError, TSuccessErrorHook } from '@/types/generic';
import { TTimeInterval, TTimelineResource } from '@/types/timeline';

import { WEEKS_TO_ADD } from '@/shared-app/context/TimelineProjectContext';

type TTimelineResourceQueryParam = TSuccessErrorHook<
  InfiniteData<TTimelineResource[]>
> & {
  timeInterval: TTimeInterval;
};

export const TIMELINE_RESOURCE_QUERY_KEY = 'get-timeline-resource';
export default function useTimelineResourceQuery({
  enabled = true,
  onError = noop,
  timeInterval,
}: TTimelineResourceQueryParam) {
  const { workspaceId } = useContext(UserContext);
  const { sortByName } = useSortAndFilter();
  const { addNotification } = useContext(NotificationsContext);

  return useInfiniteQuery<
    TTimelineResource[],
    Error,
    InfiniteData<TTimelineResource[]>,
    [string, string | undefined | null],
    TTimeInterval
  >({
    queryKey: [TIMELINE_RESOURCE_QUERY_KEY, workspaceId],
    initialPageParam: timeInterval,
    queryFn: ({ pageParam = timeInterval }) =>
      handleApiCall(
        getTimelineResources({
          workspaceId,
          startDate: pageParam.start.getTime(),
          endDate: pageParam.end.getTime(),
          sortByName,
        }),
        undefined,
        (error: TResponseError) => {
          onError(error);
          addNotification({
            type: 'error',
            title: t('errors:generic.title') as string,
            description:
              error?.message ?? (t('errors:generic.description') as string),
          });
        },
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !isUndefined(workspaceId) && enabled,
    getNextPageParam: (_lastPage, _allPages, lastPageParam) => ({
      start: startOfDay(addWeeks(lastPageParam.end, { days: 1 })),
      end: addWeeks(lastPageParam.end, { weeks: WEEKS_TO_ADD }),
    }),
    getPreviousPageParam: (_lastPage, _allPages, lastPageParam) => ({
      start: subWeeks(lastPageParam.start, { weeks: WEEKS_TO_ADD }),
      end: endOfDay(subWeeks(lastPageParam.start, { days: 1 })),
    }),
  });
}
