import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  IconChevronsDown,
  IconCircleXFilled,
  IconDots,
  IconSearch,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { t } from 'i18next';
import { RightSlot, contextMenu } from 'react-contexify';
import { ClipLoader } from 'react-spinners';
import { useDebounce } from 'use-hooks';

import { UserContext } from '@/contexts/UserContext';
import { useAnalytics } from '@/hooks/utils/useAnalytics';
import useCompletedQuery from '@/hooks/workspace/projects/useCompletedProject';
import useUpdateProject from '@/hooks/workspace/projects/useUpdateProject';
import { useWorkspaceFromContext } from '@/hooks/workspace/useWorkspaceFromContext';
import { titleCase } from '@/services/helpers';
import { PROJECT_STATUS, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { TPagedQuery } from '@/types/generic';
import { TCompletedProject } from '@/types/timeline';

import Button from '@/components/Button';
import { INDICATOR_MAPPING } from '@/components/Indicators';
import Input from '@/components/Input';
import ProjectEmoji from '@/components/ProjectEmoji';
import ShortcutMenu from '@/components/ShortcutMenu';

import styles from './styles.module.css';
import Modal, { ModalProps } from '../Modal';

type ModalCompletedProjectsItemProps = {
  project: TCompletedProject;
  onStatusChanged?: (status: PROJECT_STATUS) => void;
};

function ModalCompleteddProjectItem({
  project,
  onStatusChanged,
}: ModalCompletedProjectsItemProps) {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuPositionRef = useRef<{ x: number; y: number }>(undefined);
  const MENU_ID = useMemo(
    () => `project-completed-context-menu-${project.id}`,
    [project.id],
  );
  const { workspace } = useWorkspaceFromContext();
  function getMenuPosition() {
    const { left, bottom, width } =
      triggerRef?.current?.getBoundingClientRect() ?? {
        left: 0,
        bottom: 0,
        width: 0,
      };
    menuPositionRef.current = { x: left - (200 - width), y: bottom + 8 };
    return menuPositionRef.current;
  }
  const onClickFn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (menuIsOpen) {
        contextMenu.hideAll();
        return;
      }
      contextMenu.show({
        id: MENU_ID,
        event: e,
        position: getMenuPosition(),
      });
    },
    [menuIsOpen, MENU_ID],
  );

  const { mutate } = useUpdateProject();

  useEffect(() => {
    if (menuIsOpen) {
      function handleClick(evt: KeyboardEvent) {
        if (evt.key === 'Escape') {
          evt.preventDefault();
          evt.stopPropagation();
          setMenuIsOpen(false);
          contextMenu.hideAll();
        }
      }
      const option = { capture: true };
      window.addEventListener('keydown', handleClick, option);
      return () => window.removeEventListener('keydown', handleClick, option);
    }
  }, [menuIsOpen]);

  const updateProjectStatus = useCallback(
    (status: PROJECT_STATUS) => {
      if (project.id) {
        mutate(
          {
            ...project,
            projectId: project.id,
            status: status,
          },
          {
            onSuccess: () => onStatusChanged?.(status),
          },
        );
      }
    },
    [mutate, onStatusChanged, project],
  );

  const isReadAndWrite = useMemo(
    () => workspace?.permission === WORKSPACE_MEMBER_PERMISSION.READ_AND_WRITE,
    [workspace?.permission],
  );

  return (
    <div
      className={classNames(styles.projectItem, {
        [styles.hideShowButton]: !isReadAndWrite,
      })}
    >
      {menuIsOpen ? (
        <div
          className={styles.overlay}
          onClick={() => (setMenuIsOpen(false), contextMenu.hideAll())}
        ></div>
      ) : undefined}
      <div className={styles.emojiName}>
        <ProjectEmoji emoji={project.emoji} color={project.color} />
        <span>{project.name}</span>
      </div>
      <div className={styles.allocationBlock}>
        <span className={styles.allocation}>{project.totalAllocation}</span>
        <div className={styles.projectMenu}>
          {isReadAndWrite && (
            <React.Fragment>
              <Button
                ref={triggerRef}
                icon={IconDots}
                variant="ghost"
                onClick={onClickFn}
              />
              <ShortcutMenu
                onVisibilityChange={(isVisible: boolean) =>
                  setMenuIsOpen(isVisible)
                }
                animation={false}
                id={MENU_ID}
                style={{ width: '200px' }}
              >
                {[
                  PROJECT_STATUS.UNCONFIRMED,
                  PROJECT_STATUS.TO_DO,
                  PROJECT_STATUS.IN_PROGRESS,
                ].map((status, idx) => {
                  const Indicator = INDICATOR_MAPPING[status as PROJECT_STATUS];
                  return (
                    <ShortcutMenu.Item
                      key={`${project.id}_${status}`}
                      keyHandler={() =>
                        updateProjectStatus(status as PROJECT_STATUS)
                      }
                      keyMatch={`${idx + 1}`}
                    >
                      <div style={{ display: 'flex', gap: '8px' }}>
                        <Indicator /> {titleCase(status.replaceAll('_', ' '))}
                      </div>
                      <RightSlot>{idx + 1}</RightSlot>{' '}
                    </ShortcutMenu.Item>
                  );
                })}
              </ShortcutMenu>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

type Props = ModalProps & {
  totalProjects: number;
};
export default function ModalCompletedProjects({
  totalProjects = 0,
  ...modalProps
}: Props) {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const debouncedValue = useDebounce(searchValue, 300);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data, isLoading, fetchNextPage } = useCompletedQuery({
    query:
      debouncedValue && debouncedValue.length > 2 ? debouncedValue : undefined,
  });
  const { workspaceId } = useContext(UserContext);
  const { trackPage } = useAnalytics();
  useEffect(() => {
    if (trackPage && workspaceId)
      trackPage('PROJECTS_COMPLETED', workspaceId as string);
  }, [trackPage, workspaceId]);

  const projects = useMemo(() => {
    return (
      data?.pages.slice(0, currentPage)?.reduce((acc, page) => {
        return [...acc, ...(page?.results ?? [])];
      }, [] as Array<TCompletedProject>) ?? []
    );
  }, [currentPage, data?.pages]);

  const lastPage = useMemo(() => {
    if (!data?.pages) return undefined;
    return data?.pages?.[currentPage - 1];
  }, [currentPage, data?.pages]);

  const viewMoreFn = useCallback(
    (lastPage: TPagedQuery<TCompletedProject> | undefined) => {
      fetchNextPage();
      setCurrentPage((currePage) =>
        lastPage?.nextPage ? currePage + 1 : currePage,
      );
    },
    [fetchNextPage],
  );

  const handleStatusChange = useCallback(() => setSearchValue(undefined), []);

  return (
    <Modal
      {...modalProps}
      title={t('timeline:completedProjects.title')}
      panelClassName={[modalProps.bodyClassName ?? '', styles.panelClass]}
    >
      <div className={styles.container}>
        {(totalProjects ?? 0) >= 8 && (
          <Input
            LeadingIcon={<IconSearch size={16} color="#b0b3bb" />}
            TrailingIcon={
              <div className={styles.trailinSearchContainer}>
                {isLoading && <ClipLoader size={24} />}
                {!!searchValue && (
                  <IconCircleXFilled
                    className={styles.clearSearchIcon}
                    size={16}
                    onClick={() => setSearchValue(undefined)}
                  />
                )}
              </div>
            }
            name={'search'}
            value={searchValue ?? ''}
            placeholder={t('timeline:completedProjects.input.placeholder')}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        )}
        <div className={styles.panelWrapper}>
          {projects?.length
            ? projects.map((project) => (
                <ModalCompleteddProjectItem
                  key={project.id}
                  project={project}
                  onStatusChanged={handleStatusChange}
                />
              ))
            : !isLoading && (
                <span className={styles.notFound}>
                  {t('timeline:completedProjects.notFound')}
                </span>
              )}
        </div>
      </div>
      {lastPage?.nextPage && (
        <div
          tabIndex={0}
          role="button"
          onKeyDown={({ key }) =>
            key === 'Enter' ? viewMoreFn(lastPage) : null
          }
          className={styles.footerAction}
          onClick={() => viewMoreFn(lastPage)}
        >
          <IconChevronsDown size={16} />
          <span className={styles.footerActionText}>View more</span>
        </div>
      )}
    </Modal>
  );
}
