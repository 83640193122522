import { PropsWithChildren } from 'react';

import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';

type Props = PropsWithChildren<{
  title?: string;
}>;
export default function Layout({ children, title }: Props) {
  return (
    <main>
      <meta name="robots" content="noindex" data-sj-noindex />
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`
              default-src 'self';
              script-src 'self' https://vercel.live https://cdn.paddle.com https://*.profitwell.com;
              style-src 'self' 'unsafe-inline';
              font-src 'self';
              img-src 'self' data: https://firebasestorage.googleapis.com https://cdn.jsdelivr.net;
              connect-src 'self'
                  http://localhost:5173 ws://localhost:4001 https://*.hellotime.io
                  https://*.herokuapp.com https://*.vercel.app
                  https://*.googleapis.com https://*.sentry.io https://*.june.so https://*.paddle.com
                  wss://*.herokuapp.com
                  wss://*.hellotime.io;
              frame-src https://*.paddle.com;
              object-src 'none';
              base-uri 'self';
              form-action 'self';
              upgrade-insecure-requests;
          `}
        />
        <title>{title ? title : t('common:title')}</title>
      </Helmet>
      {children}

      <div id="radix-tooltip-portal"></div>
    </main>
  );
}
