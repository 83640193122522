import { ReactNode } from 'react';

import * as CB from '@radix-ui/react-checkbox';
import { IconCheck } from '@tabler/icons-react';
import classNames from 'classnames';

import styles from './styles.module.css';

type Props = {
  name: string;
  value: boolean;
  onChange?: (checked: boolean | 'indeterminate') => void;
  error?: string;
  disabled?: boolean;
  label?: string | ReactNode;
};

export default function Checkbox({
  name,
  value,
  onChange,
  error,
  label,
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.checkboxWrapper}>
        <CB.Root
          name={name}
          id={name}
          className={classNames(styles.checkbox, {
            [styles.checked]: value,
          })}
          checked={value}
          onCheckedChange={onChange}
        >
          <CB.Indicator className={styles.indicator}>
            <IconCheck color="white" size={20} />
          </CB.Indicator>
        </CB.Root>
        {label && (
          <label className={styles.label} htmlFor={name}>
            {label}
          </label>
        )}
      </div>

      {typeof error === 'string' && (
        <span className={styles.error}>{error}</span>
      )}
    </div>
  );
}
